import { useCurrrentRoute } from 'common/hooks/use-current-route';
import { userService } from 'common/services/user.service';
import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInternalAuthContext } from 'root/auth';

export function Redirects({ children }: PropsWithChildren): ReactElement {
  const nav = useNavigate();

  const { isAuthLoading } = useInternalAuthContext();
  const { isProtectedRoute, route } = useCurrrentRoute();

  const isL3 = userService.ensureLevel('L3');

  useEffect(() => {
    if (!isProtectedRoute || !route || route.isAvailable || isAuthLoading) return;
    const path = isL3 ? '/content' : '/start';
    nav(path);
  }, [isProtectedRoute, nav, route, isAuthLoading, isL3]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children} </>;
}
