import { Box } from '@mui/material';
import { NavItem } from 'common/constants/nav';
import { Styles } from 'common/types/styles';
import { ReactElement } from 'react';
import { NavRowItem } from 'root/layout/menu/nav-row-item';

const getStyles = (): Styles => ({
  container: { display: 'flex', flexDirection: 'column' },
  navItem: { display: 'flex', alignItems: 'center' },
});

interface Props {
  level?: number;
  nestedExpands?: Array<() => void>;
  items: Array<NavItem>;
}

export function NavItems({ level = 1, items, nestedExpands = [] }: Props): ReactElement {
  const styles = getStyles();

  return (
    <Box sx={styles.container}>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <NavRowItem key={`${index}-${item.path}`} config={item} level={level} nestedExpands={nestedExpands} />
      ))}
    </Box>
  );
}
