import { ChangeEvent, MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import { SelectField, SelectFieldOption } from 'common/ui/inputs/select-field';
import { ActionsOption, ActionsPopover } from 'common/ui/inputs/actions-popover';
import { usePdpPlaceholdersQuery, usePdpTemplatesQuery } from 'common/hooks/api/queries/use-pdp-query';
import { extractBuggyAttributeArray } from 'common/utils/arrays';
import { AIModelsSelect } from 'common/ui/inputs/ai-models-select';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { isEqual } from 'lodash';
import { useSavePdpTemplateMutation, useSelectPdpTemplateMutation } from 'common/hooks/api/mutations/use-pdp-mutation';
import { RetailerType } from 'common/constants/entities';
import { userService } from 'common/services/user.service';
import { PDPType } from 'common/types/common';
import { format } from 'date-fns';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ProductAITabValue } from './constants';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 2, mb: 2, pb: 2, borderBottom: '1px solid #7f82992a' },
  head: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' },
  modelSelect: { flexGrow: 1 },
  templateSelect: { width: 250 },
  typeSelect: { width: 250 },
  templateSelectContainer: { display: 'flex', alignItems: 'center', gap: 0.5 },
  btn: { flexGrow: 1 },
  label: { color: '#7F8299', fontSize: 12 },
  labelWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

interface Props {
  selectedTab: ProductAITabValue;
  pid: string;
  type: PDPType;
}

export function PdpTemplateType({ selectedTab, pid, type }: Props): ReactElement {
  const [prompt, setPrompt] = useState<string>('');
  const [template, setTemplate] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [placeholdersAnchorEl, setPlaceholdersAnchorEl] = useState<HTMLButtonElement>();
  const [selectedTemplateID, setSelectedTemplateID] = useState<number | string>();
  const [defaultTemplateID, setDefaultTemplateID] = useState<number | string>();

  const { data: placeholders, isFetching: isPlaceholdersFetching } = usePdpPlaceholdersQuery({
    ...(userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)
      ? { wpid: pid }
      : { tcin: pid }),
  });
  const {
    data: templatesData,
    isFetched: isFetchedTemplates,
    refetch,
  } = usePdpTemplatesQuery({
    process: type?.value,
  });
  const { mutate: mutateDefaultTemplate } = useSelectPdpTemplateMutation();

  const { mutate: mutateSavePromptTemplate, isLoading: isSavePromptTemplateLoading } = useSavePdpTemplateMutation();

  const placeholdersOptions: Array<ActionsOption> = useMemo(() => {
    return Object.keys(placeholders ?? {}).map(key => ({
      name: key,
      value: key,
    }));
  }, [placeholders]);

  const templatesOptions: Array<SelectFieldOption> = useMemo(() => {
    return (templatesData?.templates ?? []).map(t => ({
      label: `ver.${t?.version} ${format(new Date(t?.created_at), 'yyyy-MM-dd')} ${t?.description ?? ''}`,
      value: t.id,
    }));
  }, [templatesData?.templates]);

  const isTemplateChanged = useMemo(() => {
    const selectedTemplate = (templatesData?.templates ?? []).find(t => t.id === selectedTemplateID);

    return !isEqual(
      { template, model: selectedModel },
      { template: selectedTemplate?.template, model: selectedTemplate?.model_name }
    );
  }, [templatesData?.templates, template, selectedModel, selectedTemplateID]);

  const handlePlaceholdersOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setPlaceholdersAnchorEl(e.currentTarget);
  };

  const handlePlaceholdersClose = () => {
    setPlaceholdersAnchorEl(null);
  };

  const handleTemplateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplate(e.target.value);
  };

  const handleSelectModel = (value: string) => {
    setSelectedModel(value);
  };

  const handleSelectedModelChange = (value: string) => {
    handleSelectModel(value);
  };

  const handleTemplateSave = () => {
    mutateSavePromptTemplate(
      {
        template,
        process: type?.value,
        ...(type.promptType !== 'system' ? { modelName: selectedModel } : {}),
      },
      {
        onSuccess: () => {
          toast.success(`${type?.displayName} template saved succesfully`);
          refetch();
        },
      }
    );
  };

  const handleSetDefaultTemplate = () => {
    if (selectedTemplateID === defaultTemplateID || selectedTemplateID === 'default') return;

    setDefaultTemplateID(selectedTemplateID);
    mutateDefaultTemplate(
      {
        process: type?.value,
        templateId: selectedTemplateID as number,
      },
      {
        onSuccess: () => {
          toast.success(`${type?.displayName} default template changed succesfully`);
        },
      }
    );
  };

  useEffect(() => {
    if (!isFetchedTemplates) return;

    const defaultTemplate = templatesData?.templates[0]?.id;
    setSelectedTemplateID(templatesData?.selected ?? defaultTemplate ?? 'default');
  }, [isFetchedTemplates, templatesData?.selected, templatesData?.templates]);

  useEffect(() => {
    if (!selectedTemplateID) return;

    if (selectedTemplateID === 'default') setTemplate(templatesData?.defaultTemplate);
    else setTemplate((templatesData?.templates ?? []).find(t => t.id === selectedTemplateID)?.template || '');
  }, [selectedTemplateID, templatesData?.defaultTemplate, templatesData?.templates]);

  useEffect(() => {
    if (!placeholders) return;

    let prompt = template;

    Object.entries(placeholders).forEach(([key, value]) => {
      prompt = prompt?.replace(new RegExp(`{${key}}`, 'g'), extractBuggyAttributeArray(value, ','));
    });

    setPrompt(prompt);
  }, [template, placeholders]);

  useEffect(() => {
    if (!selectedTemplateID || !templatesData?.templates?.length) return;
    handleSelectModel(templatesData?.templates.find(t => t.id === selectedTemplateID)?.model_name || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateID, templatesData?.templates]);

  useEffect(() => {
    if (!isFetchedTemplates) return;
    setDefaultTemplateID(templatesData?.selected);
  }, [isFetchedTemplates, templatesData?.selected]);

  return (
    <>
      <Box sx={styles.container}>
        <Typography>{type?.displayName}</Typography>
        <Box sx={styles.head}>
          {selectedTemplateID && isFetchedTemplates && (
            <FieldLabelWrapper label="Template Version">
              <Box sx={styles.templateSelectContainer}>
                <SelectField
                  options={templatesOptions}
                  value={selectedTemplateID}
                  onChange={setSelectedTemplateID}
                  sx={styles.templateSelect}
                />
                {selectedTemplateID !== 'default' && (
                  <LightTooltip
                    title={selectedTemplateID === defaultTemplateID ? 'Default Template' : 'Set as Default'}
                    placement="right"
                  >
                    <IconButton onClick={handleSetDefaultTemplate}>
                      {selectedTemplateID === defaultTemplateID ? (
                        <CheckBoxIcon color="success" />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </IconButton>
                  </LightTooltip>
                )}
              </Box>
            </FieldLabelWrapper>
          )}

          {selectedTab === ProductAITabValue.Template && (
            <Box>
              <LightTooltip title="Copy Placeholder" placement="left">
                <IconButton onClick={handlePlaceholdersOpen}>
                  <SettingsIcon fontSize="small" />
                </IconButton>
              </LightTooltip>
            </Box>
          )}
        </Box>

        {selectedTab === ProductAITabValue.Template && (
          <TextField
            value={template}
            onChange={handleTemplateChange}
            maxRows={6}
            minRows={6}
            InputLabelProps={{ shrink: true }}
            disabled={isPlaceholdersFetching}
            fullWidth
            multiline
          />
        )}

        {selectedTab === ProductAITabValue.Prompt && (
          <TextField value={prompt} maxRows={6} minRows={6} InputLabelProps={{ shrink: true }} fullWidth multiline />
        )}

        {type.promptType !== 'system' && (
          <AIModelsSelect
            value={selectedModel}
            onChange={handleSelectedModelChange}
            sx={styles.modelSelect}
            label="AI Model"
            process="ai_optimization"
          />
        )}

        <LightTooltip title={isTemplateChanged ? '' : 'All changes are saved'} placement="top">
          <Box component="span" sx={styles.btn}>
            <ButtonWithLoading
              variant="contained"
              color="primary"
              loading={isPlaceholdersFetching || isSavePromptTemplateLoading}
              disabled={!isTemplateChanged}
              onClick={handleTemplateSave}
              fullWidth
            >
              Save Template
            </ButtonWithLoading>
          </Box>
        </LightTooltip>
      </Box>

      <ActionsPopover
        anchor={placeholdersAnchorEl}
        open={Boolean(placeholdersAnchorEl)}
        onClose={handlePlaceholdersClose}
        options={placeholdersOptions}
        onClick={value => {
          navigator.clipboard.writeText(`{${value}}`);
          toast.success('Placeholder copied!');
        }}
      />
    </>
  );
}
