import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { ReactElement } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { BackendErrorResponse } from 'common/types/common';
import { AxiosError } from 'axios';

const getWidth = (type: 'fullwidth' | 'fullscreen') => {
  switch (type) {
    case 'fullscreen':
      return '100vw';
    case 'fullwidth':
      return '100%';
    default:
      return 'unset';
  }
};

interface StylesProps {
  width: string;
}

const getStyles = ({ width }: StylesProps): Styles => ({
  container: {
    width,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
});

interface Props {
  type: 'fullwidth' | 'fullscreen';
  error: AxiosError;
}

export function AuthError({ type, error }: Props): ReactElement {
  const { logout } = useAuth0();
  const styles = getStyles({ width: getWidth(type) });

  const errorRes = error?.response;

  const errorStatus = errorRes?.status;
  const errorMessage = (errorRes?.data as BackendErrorResponse)?.errors[0]?.safeMessage;

  const getErrorMessage = () => {
    if (errorStatus === 403) {
      return 'Please contact Admin for permission';
    }
    return errorMessage;
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h1">{getErrorMessage()}</Typography>
      <LightTooltip title="Logout" placement="bottom">
        <Button sx={styles.button} onClick={() => logout({ returnTo: window.location.origin })}>
          <LogoutIcon fontSize="small" /> Logout
        </Button>
      </LightTooltip>
    </Box>
  );
}
