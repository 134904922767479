export function setCookie(key: string, value: string, expirationMS: number) {
  const now = new Date();
  now.setTime(now.getTime() + expirationMS);
  const expires = `expires=${now.toUTCString()}`;
  document.cookie = `${key}=${value};${expires};path=/`;
}

export function getCookie(key: string) {
  const name = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');
  for (let i = 0; i < cookiesArray.length; i += 1) {
    const cookie = cookiesArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}
