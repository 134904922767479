/* eslint-disable @typescript-eslint/no-unused-vars */
import { RetailerType, selectAllValue, SyndigoRetailers } from 'common/constants/entities';
import { useScopesListQuery, useWsScopesListQuery } from 'common/hooks/api/queries/use-scopes-query';
import { userService } from 'common/services/user.service';
import { Scope, WsScope } from 'common/types/common';
import { tryJSONParse } from 'common/utils/json';
import {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const __lcScope = '__saved_scope';
export const __lcWSScope = '__saved_ws_scope';

interface IScopeCtx {
  scope: Scope;
  wsScope: WsScope;
  status: string;
  isFetchedScope: boolean;
  isFetchedWsScope: boolean;
  setScope: Dispatch<Scope>;
  setWsScope: Dispatch<WsScope>;
  setStatus: Dispatch<string>;
}

export const scopeCtx = createContext<IScopeCtx>({
  scope: null,
  wsScope: null,
  status: null,
  isFetchedScope: false,
  isFetchedWsScope: false,
  setScope: (value: Scope) => undefined,
  setWsScope: (value: WsScope) => undefined,
  setStatus: (value: string) => undefined,
});

export function ScopeCtx({ children }: PropsWithChildren): ReactElement {
  const [scope, setScope] = useState<Scope>(null);
  const [wsScope, setWsScope] = useState<WsScope>(null);
  const [isFetchedScope, setIsFetchedScope] = useState<boolean>(false);
  const [isFetchedWsScope, setIsFetchedWsScope] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(null);

  const { data: scopes } = useScopesListQuery({
    ws_scope: wsScope?.ws_scope,
    productsCount: true,
    categoriesCount: true,
    limit: 100,
    sort: 'created_at:desc',
    sub_vendor_id: userService.getSubRetailer(),
  });
  const { data: wsScopes } = useWsScopesListQuery();

  useEffect(() => {
    if (!scope && scopes?.length) {
      let savedScope: Scope = tryJSONParse(localStorage.getItem(__lcScope));
      savedScope = scopes.find(s => s.id === savedScope?.id);

      setScope(
        savedScope ?? {
          id: selectAllValue,
          name: selectAllValue,
        }
      );
      setIsFetchedScope(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, scopes]);

  useEffect(() => {
    if (!wsScope && wsScopes?.length) {
      let savedWSScope: WsScope = tryJSONParse(localStorage.getItem(__lcWSScope));
      savedWSScope = wsScopes.find(wss => wss.ws_scope === savedWSScope?.ws_scope);

      setWsScope(savedWSScope ?? wsScopes[0]);
      setIsFetchedWsScope(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsScope, wsScopes]);

  useEffect(() => {
    if (!scope) return;
    localStorage.setItem(__lcScope, JSON.stringify(scope));
  }, [scope]);

  useEffect(() => {
    if (!wsScope) return;
    localStorage.setItem(__lcWSScope, JSON.stringify(wsScope));
  }, [wsScope]);

  useEffect(() => {
    if (userService.ensureRetailer(RetailerType.Instacart)) {
      setScope({
        id: selectAllValue,
        name: selectAllValue,
      });
      setIsFetchedScope(true);
    }
  }, []);

  const value = useMemo(() => {
    return {
      scope,
      status,
      wsScope,
      setScope,
      setWsScope,
      setStatus,
      isFetchedScope,
      isFetchedWsScope,
    };
  }, [scope, status, wsScope, isFetchedScope, isFetchedWsScope]);

  return <scopeCtx.Provider value={value}>{children}</scopeCtx.Provider>;
}

export function useScopeContext() {
  const ctx = useContext(scopeCtx);

  return ctx;
}
