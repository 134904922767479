import { IconButton, IconButtonProps } from '@mui/material';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { ExtendedContentEntry } from 'common/types/common';
import { useProductIssues } from 'common/hooks/use-product-issues';

interface Props {
  buttonProps?: IconButtonProps;
  attributeKeys: Array<string>;
  product?: ExtendedContentEntry;
  emptyHidden?: boolean;
}

export function IssuesButton({ buttonProps = {}, attributeKeys, product, emptyHidden = false }: Props) {
  const { issues } = useProductIssues({ attributeKeys, product });

  if (emptyHidden && !issues.length) return null;

  return (
    <LightTooltip title="Issues">
      <IconButton {...buttonProps} size="small" color={issues.length ? 'error' : undefined}>
        <NotificationImportantIcon fontSize="small" color={issues.length ? 'error' : 'action'} />
      </IconButton>
    </LightTooltip>
  );
}
