import { TFunction } from 'i18next';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import SettingsIcon from '@mui/icons-material/Settings';
import LayersIcon from '@mui/icons-material/Layers';
import ViewListIcon from '@mui/icons-material/ViewList';
import NotesIcon from '@mui/icons-material/Notes';
import AppsIcon from '@mui/icons-material/Apps';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CategoryIcon from '@mui/icons-material/Category';
import QueueIcon from '@mui/icons-material/Queue';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PreviewIcon from '@mui/icons-material/Preview';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ImageIcon from '@mui/icons-material/Image';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { userService } from 'common/services/user.service';
import { RetailerType } from './entities';
import { RoutesAvailability } from './routes-availability';

export interface NavItem {
  title: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & {
    muiName: string;
  };
  nested?: Array<NavItem>;
  isAvailable: boolean;
  demoBlur: boolean;
}

const isL3Walmart = () => userService.ensureRetailer(RetailerType.Walmart) && userService.ensureLevel('L3');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMainNavConfig = (t: TFunction): Array<NavItem> => [
  {
    title: userService.ensureRetailer(RetailerType.Walmart) ? 'Overview' : 'Products',
    path: '/',
    icon: LayersIcon,
    isAvailable: RoutesAvailability.Overview(),
    demoBlur: true,
  },
  {
    title: isL3Walmart ? 'Products' : 'Content',
    path: '/content',
    icon: ViewListIcon,
    isAvailable: RoutesAvailability.ContentManagement(),
    demoBlur: true,
  },
  {
    title: 'Categories',
    path: '/categories-settings',
    icon: CategoryIcon,
    isAvailable: RoutesAvailability.CategoriesSettings(),
    demoBlur: true,
  },
  {
    title: 'Templates',
    path: '/templates',
    icon: AppsIcon,
    isAvailable: RoutesAvailability.TemplatesManagement(),
    demoBlur: true,
  },
  {
    title: 'Comments',
    path: '/comments',
    icon: NotesIcon,
    isAvailable: RoutesAvailability.Comments(),
    demoBlur: true,
  },
  {
    title: 'Product Enrichment',
    icon: ImportExportIcon,
    isAvailable: true,
    demoBlur: false,
    nested: [
      {
        title: 'Add XLSX',
        path: '/add-xlsx',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddXLSX(),
        demoBlur: true,
      },
      {
        title: 'Add TCINs',
        path: '/add-tcins',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddTCINs(),
        demoBlur: true,
      },
      {
        title: 'Add ASINs',
        path: '/add-asins',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddASINs(),
        demoBlur: true,
      },
      {
        title: 'Add SKUs',
        path: '/add-skus',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddSKUs(),
        demoBlur: true,
      },
      {
        title: userService.ensureRetailer(RetailerType.Walmart) ? 'Walmart Change Tracking' : 'Target Change Tracking',
        path: '/change-tracking',
        icon: ManageHistoryIcon,
        isAvailable: RoutesAvailability.ChangeTracking(),
        demoBlur: false,
      },
    ],
  },
  {
    title: 'AI',
    icon: AutoAwesomeIcon,
    isAvailable: true,
    demoBlur: false,
    nested: [
      {
        title: 'Queue',
        path: '/ai-queue',
        icon: QueueIcon,
        isAvailable: RoutesAvailability.AIQueue(),
        demoBlur: false,
      },
      {
        title: 'Instructions',
        path: '/ai-instructions',
        icon: AltRouteIcon,
        isAvailable: RoutesAvailability.AIInstructions(),
        demoBlur: false,
      },
      {
        title: 'Swatch Creator',
        path: '/swatch-creator',
        icon: ImageIcon,
        isAvailable: RoutesAvailability.SwatchCreator(),
        demoBlur: false,
      },
    ],
  },
  {
    title: 'Performance',
    icon: ShowChartIcon,
    isAvailable: RoutesAvailability.Performance(),
    demoBlur: false,
    nested: [
      {
        title: 'Leaderboard',
        path: '/leaderboard',
        icon: LeaderboardIcon,
        isAvailable: RoutesAvailability.Leaderboard(),
        demoBlur: false,
      },
      {
        title: 'AI',
        icon: AutoAwesomeIcon,
        isAvailable: true,
        demoBlur: false,
        nested: [
          {
            title: 'Benchmark',
            path: '/ai-benchmark',
            icon: InsertChartIcon,
            isAvailable: RoutesAvailability.AIBenchmark(),
            demoBlur: false,
          },
          {
            title: 'Benchmark Stats ',
            path: '/ai-benchmark-stats',
            icon: DonutLargeIcon,
            isAvailable: RoutesAvailability.AIBenchmarkStats(),
            demoBlur: false,
          },
        ],
      },
      {
        title: 'Compare',
        icon: GroupsIcon,
        isAvailable: true,
        demoBlur: false,
        nested: [
          {
            title: 'Benchmark',
            path: '/compare-benchmark',
            icon: InsertChartIcon,
            isAvailable: RoutesAvailability.CompareBenchmark(),
            demoBlur: false,
          },
          {
            title: 'Benchmark Stats ',
            path: '/compare-benchmark-stats',
            icon: DonutLargeIcon,
            isAvailable: RoutesAvailability.CompareBenchmarkStats(),
            demoBlur: false,
          },
        ],
      },
    ],
  },
  {
    title: 'Quick Start',
    path: '/start',
    icon: PreviewIcon,
    isAvailable: true,
    demoBlur: true,
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getSettingsNavConfig = (t: TFunction): Array<NavItem> => [
  {
    title: 'Support',
    path: '/support',
    icon: ForumIcon,
    isAvailable: RoutesAvailability.Support(),
    demoBlur: true,
  },
  {
    title: 'Settings',
    path: '/settings/team',
    icon: SettingsIcon,
    isAvailable: RoutesAvailability.Settings(),
    demoBlur: true,
  },
];
