import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import { CategoriesParentsResponse, ExportResponse, ScopeId } from 'common/types/common';
import { stringifySort } from 'common/utils/sort';
import { selectAllValue } from 'common/constants/entities';
import {
  CategoriesParentsFilters,
  GetCategoryHistoryFilters,
  GetCategoryHistoryResponse,
  GetProductSyncListResponse,
  GetProductsBySyncIdResponse,
  GetWalmartHistoryFilters,
  GetWalmartHistoryResponse,
  ScopeCategoryStatsFilters,
  ScopeCategoryStatsResponse,
  ScopesResponse,
  ScopesSpreadResponse,
  ScopesSpreadsFilters,
  ScopesStatsFilters,
  ScopesStatsResponse,
  ScopesSummaryResponse,
  ScopesUsersFilters,
  ScopesUsersResponse,
  ScopesWsFilters,
  WsScopesListResponse,
} from './scopes-api.types';

function parseScopesSpreadFilters(args?: ScopesSpreadsFilters) {
  if (!args) return undefined;

  return {
    ...(args?.id !== selectAllValue ? { scopeID: args?.id } : {}),
    ...(args?.ws ? { wsScope: args?.ws } : {}),
    ...(args?.offset ? { offset: args?.offset } : {}),
    ...(args?.limit ? { limit: args?.limit } : {}),
    ...(args?.sort ? { sort: stringifySort(args.sort) } : {}),
    ...(args?.search ? { search: args?.search } : {}),
    ...(args?.breakdown ? { breakdown: args?.breakdown } : {}),
    ...(args?.parentCategoryID ? { parentCategoryID: args?.parentCategoryID } : {}),
    ...(args?.gte !== undefined ? { 'aiCorrectness[gte]': args?.gte } : {}),
    ...(args?.lte !== undefined ? { 'aiCorrectness[lte]': args?.lte } : {}),
  };
}

function parseScopesUsersFilters(args?: ScopesUsersFilters) {
  if (!args) return undefined;

  return {
    ...(args?.offset ? { offset: args?.offset } : {}),
    ...(args?.limit ? { limit: args?.limit } : {}),
    ...(args?.sort ? { sort: stringifySort(args.sort) } : {}),
    ...(args?.startDate ? { startDate: args?.startDate } : {}),
    ...(args?.endDate ? { endDate: args?.endDate } : {}),
    ...(args?.scopeID ? { scopeID: args?.scopeID } : {}),
    ...(args?.wsScope ? { wsScope: args?.wsScope } : {}),
    ...(args?.search ? { search: args?.search } : {}),
  };
}

function parseScopeCategoryStatsFilters(args?: ScopeCategoryStatsFilters) {
  if (!args) return undefined;

  return {
    ...(args?.scopeID ? { scopeID: args?.scopeID } : {}),
    ...(args?.wsScope ? { wsScope: args?.wsScope } : {}),
  };
}

function parseScopesStatsFilters(args?: ScopesStatsFilters) {
  if (!args) return undefined;

  return {
    ...(args?.daily ? { daily: args.daily } : {}),
    ...(args?.scopeID && args?.scopeID !== selectAllValue ? { scopeID: args.scopeID } : {}),
    ...(args?.wsScope ? { wsScope: args.wsScope } : {}),
  };
}

function parseScopesListFilters(args?: ScopesWsFilters) {
  if (!args) return undefined;

  return {
    ...(args?.ws_scope ? { ws_scope: args.ws_scope } : {}),
    ...(args?.search ? { search: args.search } : {}),
    ...(args?.tags ? { tags: args.tags } : {}),
    ...(args?.categoriesCount ? { categoriesCount: true } : {}),
    ...(args?.productsCount ? { productsCount: true } : {}),
    ...(args?.limit ? { limit: args.limit } : {}),
    ...(args?.withExportLog ? { withExportLog: true } : {}),
    ...(args?.withTags ? { withTags: true } : {}),
    ...(args?.sort ? { sort: args.sort } : {}),
    ...(args?.pendingReviewCount ? { pendingReviewCount: args.pendingReviewCount } : {}),
    ...(args?.sub_vendor_id ? { sub_vendor_id: args.sub_vendor_id } : {}),
  };
}

function parseCategoriesParentsFilters(args?: CategoriesParentsFilters) {
  if (!args) return undefined;

  return {
    ...(args?.scopeID && args?.scopeID !== selectAllValue ? { scopeID: args?.scopeID } : {}),
    ...(args?.wsScope ? { wsScope: args?.wsScope } : {}),
    ...(args?.offset ? { offset: args?.offset } : {}),
    ...(args?.limit ? { limit: args?.limit } : {}),
    ...(args?.s ? { s: args?.s } : {}),
  };
}

class ScopesApiService extends ApiService {
  getWalmartScopesList = (args?: ScopesWsFilters): Promise<AxiosResponse<ScopesResponse>> => {
    return this.get('/scopes/list', {
      params: parseScopesListFilters(args),
    });
  };

  getScopesList = (retailer: string, args?: ScopesWsFilters): Promise<AxiosResponse<ScopesResponse>> => {
    return this.get(`/vendors/${retailer}/scopes/list`, {
      params: parseScopesListFilters(args),
    });
  };

  deleteWalmartScope = (id: ScopeId) => {
    return this.delete(`/scopes/${id}`);
  };

  deleteRetaileScope = (retailer: string, id: ScopeId) => {
    return this.delete(`/vendors/${retailer}/scopes/${id}`);
  };

  getCategoryHistory = ({
    categoryID,
    scopeID,
    retailer,
  }: GetCategoryHistoryFilters): Promise<AxiosResponse<GetCategoryHistoryResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/scopes/${scopeID}/sync-history`, {
      params: { category_id: categoryID },
    });
  };

  getWalmartCategoryHistory = ({
    scopeID,
  }: GetWalmartHistoryFilters): Promise<AxiosResponse<GetWalmartHistoryResponse>> => {
    return this.get('/content/history', {
      params: { ...(scopeID ? { scope_id: scopeID } : {}) },
    });
  };

  getProductSyncList = (): Promise<AxiosResponse<GetProductSyncListResponse>> => {
    return this.get('/product-sync/list');
  };

  getProductsBySyncId = (syncId: number): Promise<AxiosResponse<GetProductsBySyncIdResponse>> => {
    return this.get(`/product-sync/${syncId}/products`);
  };

  getWsScopes = (): Promise<AxiosResponse<WsScopesListResponse>> => {
    return this.get('/scopes/ws/list');
  };

  getScopesStats = (args: ScopesStatsFilters): Promise<AxiosResponse<ScopesStatsResponse>> => {
    return this.get('/scopes/stats', {
      params: parseScopesStatsFilters(args),
    });
  };

  getScopesSummary = (id: ScopeId, ws: number): Promise<AxiosResponse<ScopesSummaryResponse>> => {
    return this.get('/scopes/summary', {
      params: {
        ...(id !== selectAllValue ? { scopeID: id } : {}),
        ...(ws ? { wsScope: ws } : {}),
      },
    });
  };

  getScopesSpread = (args: ScopesSpreadsFilters): Promise<AxiosResponse<ScopesSpreadResponse>> => {
    return this.get('/scopes/spread-remaining', {
      params: parseScopesSpreadFilters(args),
    });
  };

  getScopeCategoryStats = (args: ScopeCategoryStatsFilters): Promise<AxiosResponse<ScopeCategoryStatsResponse>> => {
    return this.get(`/scopes/category/${args?.id}/stats`, { params: parseScopeCategoryStatsFilters(args) });
  };

  getAttributesExport = (categoryID: number): Promise<AxiosResponse<ExportResponse>> => {
    return this.get('/attributes/updated/export', {
      params: {
        ...(categoryID ? { categoryID } : {}),
      },
    });
  };

  getScopesUsers = (args: ScopesUsersFilters): Promise<AxiosResponse<ScopesUsersResponse>> => {
    return this.get('/scopes/users-stats', {
      params: parseScopesUsersFilters({ ...args, ...(args?.id !== selectAllValue ? { scopeID: args?.id } : {}) }),
    });
  };

  getScopesUsersExport = (args: ScopesUsersFilters): Promise<AxiosResponse<ExportResponse>> => {
    return this.get('/scopes/users-stats/export', { params: parseScopesUsersFilters(args) });
  };

  getCategoriesParents = (args: CategoriesParentsFilters): Promise<AxiosResponse<CategoriesParentsResponse>> => {
    return this.get('/categories/parents/search', { params: parseCategoriesParentsFilters(args) });
  };
}

export const scopesApi = new ScopesApiService(process.env.REACT_APP_AI_API);
