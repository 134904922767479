const createUnitsRegex = (units: Array<string>) => {
  return new RegExp(`(${units?.join('|')})(?!\\w)`, 'gi');
};

export const replaceUnits = (value: string, units: Array<string>) => {
  return value ? String(value).replace(createUnitsRegex(units), '') : '';
};

export const replaceNumber = (value: string, units: Array<string>) => {
  const executedRegex = createUnitsRegex(units).exec(value);
  return executedRegex ? executedRegex[0].trim() : '';
};
