import {
  Box,
  Button,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Styles } from 'common/types/styles';
import { HashContainer } from 'common/ui/containers/hash-container';
import { StatusDropdown } from 'common/ui/inputs/status-dropdown';
import WorkIcon from '@mui/icons-material/Work';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Fragment,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { tryJSONParse } from 'common/utils/json';
import { usePathsMatch } from 'common/hooks/use-paths-match';

const LC = '__jobs_cache';

const hiddenRoutes = ['/compare-benchmark'];

interface JobEntry {
  data?: Array<{
    type: 'link';
    value: string;
  }>;
  name: string;
  loading: boolean;
  error?: string;
  createdAt: number;
}

const getStyles = (): Styles => ({
  statusesButton: {
    position: 'fixed',
    bottom: 32,
    left: 32,
    zIndex: 9999,
    opacity: 0.5,
    color: '#A8A9B2',
  },
  statusesContainer: {
    width: 960,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    overflowY: 'scroll',
    padding: 2,
    boxSizing: 'border-box',
  },
  linksContainer: { gap: 0.5 },
  link: {
    fontSize: 12,
    width: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  popover: { opacity: 0.9 },
  empty: { fontSize: 12, ml: 2 },
});

const context = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleTriggered: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createJobEntry: (id: string, name: string) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateJobEntry: (id: string, entry: Partial<JobEntry>) => undefined,
});

export function StatusesContainer({ children }: PropsWithChildren): ReactElement {
  const [jobs, setJobs] = useState<Record<string, JobEntry>>(tryJSONParse(localStorage.getItem(LC)) ?? {});
  const [statusesOpen, setStatusesOpen] = useState<boolean>(false);

  const hidden = usePathsMatch(hiddenRoutes);
  const styles = getStyles();

  const statusesBtnRef = useRef<HTMLButtonElement>();

  const handleStatusesOpen = useCallback(() => {
    setStatusesOpen(true);
  }, []);

  const handleStatusesClose = () => {
    setStatusesOpen(false);
  };

  const cacheJobs = useCallback((jobs: Record<string, JobEntry>) => {
    localStorage.setItem(LC, JSON.stringify(jobs));
  }, []);

  const createJobEntry = useCallback((id: string, name: string) => {
    setJobs(jobs => ({ ...jobs, [id]: { loading: true, createdAt: new Date().getTime(), name } }));
    // cacheJobs();
  }, []);

  const updateJobEntry = useCallback(
    (id: string, entry: Partial<JobEntry>) => {
      setJobs(jobs => {
        const copy = {
          ...jobs,
          [id]: { ...jobs[id], ...entry },
        };
        cacheJobs(copy);
        return copy;
      });
    },
    [cacheJobs]
  );

  const removeJobEntry = (id: string) => {
    setJobs(jobs => {
      const copy = { ...jobs };
      delete copy[id];
      cacheJobs(copy);
      return copy;
    });
  };

  const handleTriggered = useCallback(() => {
    handleStatusesOpen();
  }, [handleStatusesOpen]);

  const getStatusValue = (id: string) => {
    const entry = jobs[id];

    if (entry.loading) return 'Loading';
    if (entry.error) return 'Failed';

    return 'Completed';
  };

  const value = useMemo(() => {
    return { handleTriggered, createJobEntry, updateJobEntry };
  }, [createJobEntry, handleTriggered, updateJobEntry]);

  return (
    <context.Provider value={value}>
      {children}
      {!hidden && (
        <>
          <Button
            sx={styles.statusesButton}
            color="inherit"
            variant="outlined"
            ref={statusesBtnRef}
            onClick={handleStatusesOpen}
            size="small"
          >
            <WorkIcon fontSize="small" />
            Jobs Statuses
          </Button>
          <Popover
            onClose={handleStatusesClose}
            open={statusesOpen}
            anchorEl={statusesBtnRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={styles.popover}
          >
            <Box sx={styles.statusesContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Job Name</TableCell>
                    <TableCell>Job ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Error</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(jobs)
                    .sort((a, b) => b[1].createdAt - a[1].createdAt)
                    .map(([id, entry]) => (
                      <Fragment key={id}>
                        <TableRow>
                          <TableCell>{entry.name}</TableCell>
                          <TableCell>
                            <HashContainer value={id} disableCopy />
                          </TableCell>
                          <TableCell>
                            <StatusDropdown value={getStatusValue(id)} showExpand={false} disabled />
                          </TableCell>
                          <TableCell>
                            {entry.error ? (
                              <LightTooltip title={entry.error} placement="top">
                                <IconButton size="small">
                                  <ErrorIcon fontSize="small" />
                                </IconButton>
                              </LightTooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell>
                            <LightTooltip title="Remove cache">
                              <IconButton size="small" onClick={() => removeJobEntry(id)}>
                                <DeleteIcon />
                              </IconButton>
                            </LightTooltip>
                          </TableCell>
                        </TableRow>
                        {entry.data?.filter(d => d.type === 'link') && (
                          <TableRow sx={styles.linksContainer}>
                            <TableCell colSpan={5}>
                              {entry.data
                                ?.filter(d => d.type === 'link')
                                .map(d => (
                                  <Typography component="a" href={d.value} sx={styles.link} key={d.value}>
                                    {d.value}
                                  </Typography>
                                ))}
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
              {!Object.keys(jobs).length && (
                <Typography sx={styles.empty}>There are no active or cached jobs</Typography>
              )}
            </Box>
          </Popover>
        </>
      )}
    </context.Provider>
  );
}

export function useStatusesContext() {
  const ctx = useContext(context);
  return ctx;
}
