import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { LC_PAGE_BEFORE_AUTH } from 'common/constants/lc';
import { useMeQuery } from 'common/hooks/api/queries/use-users-query';
import { useCurrrentRoute } from 'common/hooks/use-current-route';
import { ReactNode, createContext, useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthError } from 'root/errors';
import { LoadingContainer } from 'root/layout/loading-container';

export const internalAuthCtx = createContext({
  isAuthLoading: false,
});

interface Props {
  children: ReactNode;
}

export function InternalAuthProvider({ children }: Props): JSX.Element {
  const location = useLocation();
  const nav = useNavigate();

  const { isProtectedRoute } = useCurrrentRoute();

  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } = useAuth0();

  const tokenQuery = useQuery({
    queryKey: ['token'],
    enabled: Boolean(user && isAuthenticated && isProtectedRoute),
    queryFn: getAccessTokenSilently,
  });

  const { isFetching: isMeLoading, isError, error } = useMeQuery(Boolean(isProtectedRoute && tokenQuery.data));

  const isAuthLoading =
    tokenQuery.isFetching || (isLoading && isProtectedRoute) || isMeLoading || (isProtectedRoute && !isAuthenticated);

  useEffect(() => {
    if (isLoading || isAuthenticated || !isProtectedRoute) return;

    localStorage.setItem(LC_PAGE_BEFORE_AUTH, location.pathname);
    loginWithRedirect({ redirectUri: window.location.origin });
  }, [isAuthenticated, isLoading, isProtectedRoute, location.pathname, loginWithRedirect]);

  useLayoutEffect(() => {
    if (!tokenQuery.isSuccess || !tokenQuery.data) return;

    const page = localStorage.getItem(LC_PAGE_BEFORE_AUTH);

    localStorage.setItem('token', tokenQuery.data.toString());
    localStorage.removeItem(LC_PAGE_BEFORE_AUTH);

    if (!page) return;

    nav(page);
  }, [nav, tokenQuery.data, tokenQuery.isSuccess]);

  const value = useMemo(() => {
    return { isAuthLoading };
  }, [isAuthLoading]);

  const renderChildrenOrError = () => {
    if (isError) return <AuthError type="fullscreen" error={error as AxiosError} />;
    return children;
  };

  return (
    <internalAuthCtx.Provider value={value}>
      {isAuthLoading ? <LoadingContainer type="fullscreen" /> : renderChildrenOrError()}
    </internalAuthCtx.Provider>
  );
}

export function useInternalAuthContext() {
  const ctx = useContext(internalAuthCtx);

  return ctx;
}
