export enum DetailsTabValue {
  Original = 0,
  Final,
  Compare,
}

export enum OptimizationStatus {
  Regular = 0,
  SEO,
}

export enum SEOCompareSource {
  Original = 0,
  SEO,
}
