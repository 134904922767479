import { Box, Switch, Typography } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import { getBTNsStyles } from './styles';

interface Props {
  value: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export function CompareToggles({ value, handleChange }: Props): ReactElement {
  const styles = getBTNsStyles();

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.toggle}>COMPARE</Typography>
      <Switch checked={value} onChange={handleChange} color="primary" />
      <Typography sx={styles.toggle}>SEO COMPARE</Typography>
    </Box>
  );
}
