export enum ContentStatus {
  Failed = 'Failed',
  ReadyForAI = 'ReadyForAI',
  ReadyForReview = 'ReadyForReview',
  ReadyForUpload = 'ReadyForUpload',
  Uploaded = 'Uploaded',
  Issues = 'Issues',
  NoIssues = 'NoIssues',
  DoneDone = 'Done',
}

export enum ProductContentStatus {
  NoIssues = -7,
  New = 1,
  ReadyForAI = 2,
  Failed = 3,
  ReadyForReview = 4,
  ReadyForUpload = 5,
  Uploaded = 6,
  Issues = 7,
  Done = 8,
}

// keys in the tags field
export enum ProductFlagKey {
  WrongProductType = 'flag:invalid',
  PrimaryShelfMisalignment = 'flag:psma',
  CannotBeEnriched = 'flag:nenr',
  Example = 'flag:example',
}

// actual value of flag to use in filters
export enum ProductFlagValue {
  WrongProductType = 'flag:invalid@ipt',
  InsuficcientInfo = 'flag:invalid@isf',
  PrimaryShelfMisalignment = 'flag:psma',
  CannotBeEnriched = 'flag:nenr',
  Example = 'flag:example',
}

export enum QuickReviewType {
  InitialCheck = 'InitialCheck',
  SpotCheck = 'SpotCheck',
  L3 = 'L3',
}

export enum POCSubVendors {
  Costco = 1,
  Amazon = 2,
}

export enum RetailerType {
  Walmart = 'Walmart',
  Flywheel = 'Flywheel',
  Target = 'Target',
  Instacart = '1',
  Autozone = '3',
  POC = 'POC',
  Amazon = 'Amazon',
  Costco = 'Costco',
  SyndigoTarget = '2',
  SyndigoWalmart = '4',
  SyndigoAmazon = '5',
  SyndigoKroger = '6',
}

export const WalmartRetailers = [RetailerType.Walmart, RetailerType.Flywheel];

export const PocRetailers = [RetailerType.Amazon, RetailerType.Costco];

export const SyndigoRetailers = [
  RetailerType.SyndigoAmazon,
  RetailerType.SyndigoKroger,
  RetailerType.SyndigoTarget,
  RetailerType.SyndigoWalmart,
];

export const SubRetailers = [...SyndigoRetailers, RetailerType.Autozone, RetailerType.Instacart];

export const RetailersTitleMap = {
  [RetailerType.Walmart]: 'Walmart',
  [RetailerType.Flywheel]: 'Walmart (Flywheel)',
  [RetailerType.Target]: 'Target',
  [RetailerType.Instacart]: 'Instacart',
  [RetailerType.Autozone]: 'Autozone',
  [RetailerType.POC]: 'POC',
  [RetailerType.Amazon]: 'Amazon',
  [RetailerType.Costco]: 'Costco',
  [RetailerType.SyndigoTarget]: 'Syndigo (Target)',
  [RetailerType.SyndigoWalmart]: 'Syndigo (Walmart)',
  [RetailerType.SyndigoAmazon]: 'Syndigo (Amazon)',
  [RetailerType.SyndigoKroger]: 'Syndigo (Kroger)',
};

export const AllVendorsTitleMap = {
  [RetailerType.Walmart]: { label: 'Walmart', value: 'Walmart' },
  [RetailerType.Flywheel]: { label: 'Walmart (Flywheel)', value: 'Flywheel' },
  [RetailerType.Target]: { label: 'Target', value: 'Target' },
  [RetailerType.Instacart]: { label: 'Instacart', value: '1' },
  [RetailerType.Autozone]: { label: 'Autozone', value: '3' },
  Instacart: { label: 'Instacart', value: '1' },
  Autozone: { label: 'Autozone', value: '3' },
  [RetailerType.POC]: { label: 'POC', value: 'POC' },
  [RetailerType.Amazon]: { label: 'Amazon', value: 'Amazon' },
  [RetailerType.Costco]: { label: 'Costco', value: 'Costco' },
  [RetailerType.SyndigoTarget]: { label: 'Syndigo (Target)', value: '2' },
  [RetailerType.SyndigoWalmart]: { label: 'Syndigo (Walmart)', value: '4' },
  [RetailerType.SyndigoAmazon]: { label: 'Syndigo (Amazon)', value: '5' },
  [RetailerType.SyndigoKroger]: { label: 'Syndigo (Kroger)', value: '6' },
  'Syndigo - Target': { label: 'Syndigo (Target)', value: '2' },
  'Syndigo - Walmart': { label: 'Syndigo (Walmart)', value: '4' },
  'Syndigo - Amazon': { label: 'Syndigo (Amazon)', value: '5' },
  'Syndigo - Kroger': { label: 'Syndigo (Kroger)', value: '6' },
};

export enum IssuesType {
  All = 'All',
  IssuesOnly = 'Issues Only',
  NonIssuesOnly = 'Non-Issues Only',
}

export const selectAllValue = 'All';
