import { ITableSort } from 'common/types/common';

export const getSortDirection = (field: string, sort: ITableSort): 'asc' | 'desc' => {
  if (!sort) return 'asc';
  if (field === sort?.field && sort?.direction === 'asc') return 'desc';
  if (field === sort?.field && sort?.direction === 'desc') return 'asc';
  if (field !== sort?.field) return sort?.direction;
  return 'asc';
};

export const stringifySort = (sort: ITableSort) => {
  return `${sort.field}:${sort.direction}`;
};
