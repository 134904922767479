import { ReactElement } from 'react';

interface Props {
  color?: string;
}

export function Logo({ color = '#fff' }: Props): ReactElement {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.34 8.38084L11.9567 16.1894L14.917 8.8625L12.2382 4.07291L10.2667 6.20519L12.1311 8.27318L4.85805 16.1894L7.66215 8.80644L4.85805 4.12118L0.705078 8.80644L4.85805 0.252441L9.57874 5.57645L12.3118 0.252441L19.34 8.38084Z"
        fill={color}
      />
    </svg>
  );
}
