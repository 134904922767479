import {
  Box,
  Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { theme } from 'common/constants/theme';
import { PdpRequestMessage } from 'common/types/common';
import { Styles } from 'common/types/styles';
import { TruncateContainer } from 'common/ui/containers/truncate-container';
import { ReactElement } from 'react';
import toast from 'react-hot-toast';

const styles: Styles = {
  container: {
    width: 400,
    height: 280,
    padding: 2,
    boxSizing: 'border-box',
  },
  content: { whiteSpace: 'pre-wrap' },
  copyWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noData: { fontSize: 12, color: theme.palette.grey[700], margin: '0 auto', display: 'block', width: 'max-content' },
};

interface Props {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  data: Array<PdpRequestMessage>;
}

export function RequestPopup({ anchorElement, onClose, data }: Props): ReactElement {
  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={styles.container}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Role</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((message, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell>{message?.role}</TableCell>
                  <TableCell>
                    <Box sx={styles.copyWrapper}>
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            JSON.stringify(
                              typeof message?.content === 'string' ? message?.content : JSON.stringify(message?.content)
                            )
                          );
                          toast.success('Message copied!');
                        }}
                        size="small"
                      >
                        Copy
                      </Button>
                    </Box>
                    <TruncateContainer maxHeight={70}>
                      <Box sx={styles.content}>
                        {typeof message?.content === 'string' ? message?.content : JSON.stringify(message?.content)}
                      </Box>
                    </TruncateContainer>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!data?.length && <Typography sx={styles.noData}>No data</Typography>}
        </TableContainer>
      </Box>
    </Popover>
  );
}
