import { Box, Typography } from '@mui/material';
import { Logo } from 'common/svg/logo';
import { Styles } from 'common/types/styles';

const styles: Styles = {
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

export function MXAILogo() {
  return (
    <Box sx={styles.logoContainer}>
      <Logo color="#000" />
      <Typography sx={styles.logo} fontWeight={500}>
        MX
      </Typography>
      <Typography sx={[styles.logo, styles.greyedLogo]}>.AI</Typography>
    </Box>
  );
}
