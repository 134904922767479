import { TextFieldProps } from '@mui/material';
import { ReactElement } from 'react';
import { SystemStyleObject } from '@mui/system';
import { DemoBlurWrapper } from 'common/ui/containers/demo-blur-wrapper';
import { RetailerType, RetailersTitleMap } from 'common/constants/entities';
import { useRetailerContext } from '../retailer-ctx';
import { SelectField } from '../../select-field';

interface Props {
  forceRedirect?: boolean;
  sx?: SystemStyleObject;
  variant?: TextFieldProps['variant'];
  fullWidth?: boolean;
  retailerFilter?: (r: string) => boolean;
  demoBlur?: boolean;
}

export function RetailerSelect({
  demoBlur = false,
  forceRedirect = true,
  sx,
  variant = 'standard',
  retailerFilter,
  fullWidth,
}: Props): ReactElement {
  const { retailer, setRetailer, availableRetailers } = useRetailerContext();

  const options = availableRetailers
    ?.filter(r => (retailerFilter ? retailerFilter(r) : true))
    ?.map(vendor => ({
      label: RetailersTitleMap[vendor as RetailerType],
      value: vendor,
    }));

  const handleChange = (value: string) => {
    setRetailer(value as RetailerType);
    localStorage.setItem('retailer', value as RetailerType);
    if (forceRedirect) window.location.href = '/';
    else window.location.reload();
  };

  return (
    <DemoBlurWrapper>
      <SelectField
        options={options}
        variant={variant}
        value={retailer as string}
        onChange={handleChange}
        selectSx={sx}
        fullWidth={fullWidth}
        demoBlur={demoBlur}
      />
    </DemoBlurWrapper>
  );
}
