import {
  Box,
  Grid,
  IconButton,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { nanoid } from 'nanoid';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Styles } from 'common/types/styles';
import { useUpdatePDPKeywordsMutation } from 'common/hooks/api/mutations/use-attributes-mutation';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import InfoIcon from '@mui/icons-material/Info';
import { ITableSort, SavedKeyword, SEOKeywordEmphasisLevel } from 'common/types/common';
import { AttributesQueryKey, useKeywordsQuery } from 'common/hooks/api/queries/use-attributes-query';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { HashContainer } from 'common/ui/containers/hash-container';
import { getSortDirection } from 'common/utils/sort';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { useGeneratePDP } from './use-generate-pdp';
import { ApproveModal } from '../approve-modal';

enum SortField {
  SFR = 'sfr',
  EmphasisLevel = 'emphasis_level',
  Keyword = 'keyword',
}

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column' },
  keywords: {
    overflowY: 'auto',
    height: 540,
  },
  pool: { overflowY: 'auto', height: 540 },
  saveBtn: { mt: 2, width: 200 },
  keyword: { display: 'flex', alignItems: 'center', gap: 1 },
  field: { flexGrow: 1 },
  settings: { display: 'flex', alignItems: 'center', gap: 2 },
  emphasisContainer: {
    maxWidth: 300,
    width: '100%',
    mr: 3,
  },
  emphasisSlider: { width: 300 },
  infoBtn: { alignSelf: 'flex-start' },
  procedureContainer: { display: 'flex', justifyContent: 'center' },
  tableHead: { height: 80 },
};

const empasisLVLMarks = [
  {
    value: SEOKeywordEmphasisLevel.Normal,
    label: 'Normal',
  },
  {
    value: SEOKeywordEmphasisLevel.Medium,
    label: 'Medium',
  },
  {
    value: SEOKeywordEmphasisLevel.High,
    label: 'High',
  },
];

interface Props {
  pid: string;
}

export function PDPKeywords({ pid }: Props): ReactElement {
  const queryClient = useQueryClient();

  const { data: keywordsData, isFetching: isKeywordsFetching } = useKeywordsQuery(pid);

  const [keywords, setKeywords] = useState<Partial<SavedKeyword>[]>([]);
  const [sort, setSort] = useState<ITableSort>({ field: SortField.SFR, direction: 'desc' });
  const [poolExpanded, setPoolExpanded] = useState<boolean>(true);
  const [regenerateModalOpen, setRegenerateModalOpen] = useState<boolean>(false);

  const { mutate: mutateKWs, isLoading: isSaving } = useUpdatePDPKeywordsMutation();
  const { handleGenerateAI } = useGeneratePDP({
    issuesOnly: false,
    pdpGen: false,
    seoGen: true,
    pid,
  });

  const sortedKWs = useMemo(() => {
    const kws = keywords.filter(kw => !kw.id.toString().includes('deleted'));
    if (sort?.field)
      kws.sort((a, b) => {
        const aField = a[sort.field as keyof SavedKeyword];
        const bField = b[sort.field as keyof SavedKeyword];

        const numberCompare = (a: number, b: number) => (sort.direction === 'asc' ? a - b : b - a);
        const stringCompare = (a: string, b: string) =>
          sort.direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);

        if (typeof aField === 'string' && typeof bField === 'string') {
          return stringCompare(aField, bField);
        }

        if (typeof aField === 'number' && typeof bField === 'number') {
          return numberCompare(aField, bField);
        }

        return null;
      });
    return kws;
  }, [keywords, sort.direction, sort?.field]);

  const handleRegenerateOpen = () => {
    setRegenerateModalOpen(true);
  };

  const handleRegenerateClose = () => {
    setRegenerateModalOpen(false);
  };

  const addKW = (index: number) => {
    setKeywords(kws => [
      ...kws.slice(0, index + 1),
      { keyword: '', id: `new_${nanoid()}`, selected: true, emphasis_level: 1 },
      ...kws.slice(index + 1, kws.length),
    ]);
  };

  const deleteKW = (id: number | string) => {
    setKeywords(kws =>
      id.toString().includes('new')
        ? kws.filter(kw => kw.id !== id)
        : kws.map(kw => {
            if (kw.id === id) return { ...kw, id: `deleted_${id}` };
            return kw;
          })
    );
  };

  const handlePoolExpanded = () => {
    setPoolExpanded(poolExpanded => !poolExpanded);
  };

  const handleSortCreation = (field: SortField) => () => {
    setSort({ field, direction: getSortDirection(field, sort) });
  };

  const handlePool = (kwID: number | string, pool: boolean) => {
    mutateKWs(
      {
        patch: {
          entries: [
            {
              id: Number(kwID),
              selected: !pool,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
          toast.success('PDP Keywords saved successfully');
          handleRegenerateOpen();
        },
      }
    );
  };

  const handleSave = () => {
    const postKWs = keywords.filter(kw => kw.id.toString().includes('new') && kw.keyword);
    const delKWs = keywords.filter(kw => kw.id.toString().includes('deleted'));
    const patchKWs = keywords.filter(
      kw =>
        !postKWs.some(pkw => pkw.id === kw.id) &&
        !delKWs.some(pkw => pkw.id === kw.id) &&
        keywordsData.keywords.find(savedKW => savedKW.id === kw.id).keyword !== kw.keyword
    );

    mutateKWs(
      {
        post: {
          tcin: pid,
          entries: postKWs.map(kw => ({
            keyword: kw.keyword,
            emphasis_level: kw.emphasis_level,
            selected: kw.selected,
          })),
        },
        patch: {
          entries: patchKWs.map(kw => ({
            keyword: kw.keyword,
            emphasis_level: kw.emphasis_level,
            id: Number(kw.id.toString().replace('new_', '')),
          })),
        },
        del: {
          keyword_ids: delKWs.map(kw => Number(kw.id.toString().replace('deleted_', ''))),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
          toast.success('PDP Keywords saved successfully');
          handleRegenerateOpen();
        },
      }
    );
  };

  useEffect(() => {
    if (!keywordsData) return;
    const kws: Partial<SavedKeyword>[] = [...keywordsData.keywords];
    if (!kws.length)
      kws.push({
        keyword: '',
        id: `new_${nanoid()}`,
        emphasis_level: 1,
        selected: true,
      });
    setKeywords(kws);
  }, [keywordsData]);

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid xs={poolExpanded ? 9 : 12} item>
          <TableContainer sx={styles.keywords}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={styles.tableHead}>
                  <TableCell>
                    <TableSortLabel
                      active={sort?.field === SortField.Keyword}
                      onClick={handleSortCreation(SortField.Keyword)}
                      direction={sort?.direction}
                      sx={{ fontSize: 'inherit', color: 'inherit' }}
                    >
                      Keyword
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Raw Keyword</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort?.field === SortField.EmphasisLevel}
                      onClick={handleSortCreation(SortField.EmphasisLevel)}
                      direction={sort?.direction}
                      sx={{ fontSize: 'inherit', color: 'inherit' }}
                    >
                      Emphasis Level
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Procedure</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort?.field === SortField.SFR}
                      onClick={handleSortCreation(SortField.SFR)}
                      direction={sort?.direction}
                      sx={{ fontSize: 'inherit', color: 'inherit' }}
                    >
                      SFR
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>
                    <LightTooltip title={poolExpanded ? 'Hide Pool' : 'Expand Pool'} placement="right">
                      <IconButton onClick={handlePoolExpanded}>
                        <KeyboardTabIcon fontSize="small" />
                      </IconButton>
                    </LightTooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedKWs
                  .filter(kw => kw.selected)
                  .map((kw, index) => (
                    <TableRow key={kw.id}>
                      <TableCell>
                        <TextField
                          value={kw.keyword}
                          sx={styles.field}
                          disabled={isSaving || isKeywordsFetching}
                          onChange={e => {
                            setKeywords(kws =>
                              kws.map(_kw => {
                                if (_kw.id === kw.id) return { ..._kw, keyword: e.target.value };
                                return _kw;
                              })
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>{kw.original_keyword}</TableCell>
                      <TableCell>
                        <Slider
                          value={kw.emphasis_level}
                          min={1}
                          max={3}
                          marks={empasisLVLMarks}
                          sx={styles.emphasisSlider}
                          disabled={isSaving || isKeywordsFetching}
                          onChange={(_: Event, value: number | number[]) => {
                            if (Array.isArray(value)) return;
                            setKeywords(kws =>
                              kws.map(_kw => {
                                if (_kw.id === kw.id) return { ..._kw, emphasis_level: value };
                                return _kw;
                              })
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {kw.procedure ? (
                          <Box sx={styles.procedureContainer}>
                            <LightTooltip placement="top" title={kw.procedure}>
                              <IconButton size="small" sx={styles.infoBtn}>
                                <InfoIcon />
                              </IconButton>
                            </LightTooltip>
                          </Box>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>{kw.sfr ? <HashContainer value={kw.sfr} disableCopy /> : '-'}</TableCell>
                      <TableCell>{kw.source ? <HashContainer value={kw.source} disableCopy /> : '-'}</TableCell>
                      <TableCell>
                        <IconButton size="small" onClick={() => addKW(index)}>
                          <AddIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => deleteKW(kw.id)} disabled={keywords.length === 1}>
                          <DeleteIcon />
                        </IconButton>
                        {!kw.id.toString().includes('new') && (
                          <LightTooltip title="Move to Pool">
                            <IconButton size="small" onClick={() => handlePool(kw.id, true)} disabled={isSaving}>
                              <HideSourceIcon />
                            </IconButton>
                          </LightTooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {poolExpanded && (
          <Grid item xs={3}>
            <TableContainer sx={styles.pool}>
              <Table>
                <TableHead>
                  <TableRow sx={styles.tableHead}>
                    <TableCell>Add</TableCell>
                    <TableCell>Keyword</TableCell>
                    <TableCell>SFR</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedKWs
                    .filter(kw => !kw.selected)
                    .sort((a, b) => b.relevance_score - a.relevance_score)
                    .map(kw => (
                      <TableRow key={kw.id}>
                        <TableCell>
                          <IconButton size="small" onClick={() => handlePool(kw.id, false)}>
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>{kw.keyword}</TableCell>
                        <TableCell>{kw.sfr}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <ButtonWithLoading sx={styles.saveBtn} variant="contained" onClick={handleSave} loading={isSaving}>
        <SaveIcon />
        Save Keywords
      </ButtonWithLoading>

      <ApproveModal
        open={regenerateModalOpen}
        onClose={handleRegenerateClose}
        onCancel={handleRegenerateOpen}
        onConfirm={() => {
          handleGenerateAI();
          handleRegenerateClose();
        }}
        vocabulary={{
          yesBtn: 'Generate',
          noBtn: 'Cancel',
          description: 'Would you like to re-generate PDP now?',
        }}
      />
    </Box>
  );
}
