import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import { UserFilter, UserUpdateBody } from 'common/types/common';
import { CreateAssignmentBody, GetMeResponse, UsersResponse } from './users-api.types';

class UsersApiService extends ApiService {
  getUsers = (args?: UserFilter): Promise<AxiosResponse<UsersResponse>> => {
    return this.get('/users', {
      params: {
        ...(args?.page ? { offset: args.page * args.limit } : {}),
        ...(args?.limit ? { limit: args?.limit } : {}),
        ...(args?.search ? { search: args?.search } : {}),
        ...(args?.vendorsIDs?.length ? { vendorsIDs: args?.vendorsIDs } : {}),
      },
    });
  };

  createAssignment = (body: CreateAssignmentBody) => {
    return this.post('/users/assignments', body);
  };

  getMe = (): Promise<AxiosResponse<GetMeResponse>> => {
    return this.get('/users/me');
  };

  updateUser = (id: string, body: UserUpdateBody): Promise<AxiosResponse<{ message: string }>> => {
    return this.patch(`/users/${id}`, body);
  };

  deleteUser = (id: string): Promise<AxiosResponse<{ message: string }>> => {
    return this.delete(`/users/${id}`);
  };
}

export const usersApi = new UsersApiService(process.env.REACT_APP_AI_API);
