import { PropsWithChildren, ReactElement, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const selectedContentCtx = createContext({
  selectedContent: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedContent: (selectedContent: string) => undefined,
});

export function SelectedContentCtx({ children }: PropsWithChildren): ReactElement {
  const { pid } = useParams<{ pid: string }>();

  const [selectedContent, setSelectedContent] = useState<string>(pid);

  const value = useMemo(() => {
    return {
      selectedContent,
      setSelectedContent,
    };
  }, [selectedContent, setSelectedContent]);

  useEffect(() => {
    setSelectedContent(pid);
  }, [pid]);

  return <selectedContentCtx.Provider value={value}>{children}</selectedContentCtx.Provider>;
}

export function useSelectedContentContext() {
  const ctx = useContext(selectedContentCtx);

  return ctx;
}
