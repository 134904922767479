/* eslint-disable react/jsx-no-useless-fragment */
import { Fragment, ReactElement, useMemo } from 'react';
import * as diff from 'diff';
import { nanoid } from 'nanoid';
import { Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Styles } from 'common/types/styles';
import { theme } from 'common/constants/theme';

const styles: Styles = {
  added: { backgroundColor: theme.palette.success.light },
  removed: { backgroundColor: theme.palette.error.light },
};

interface Props {
  previousText: string;
  currentText: string;
  sx?: SystemStyleObject;
}

export function TextComparator({ previousText, currentText, sx }: Props): ReactElement {
  const comparedText = useMemo(() => {
    return diff.diffWords(previousText, currentText).map(change => ({ ...change, id: nanoid() }));
  }, [previousText, currentText]);

  const getCommonProps = (ct: { added?: boolean; removed?: boolean }) => {
    return {
      component: 'span',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      sx: [ct.added && styles.added, ct.removed && styles.removed].filter(Boolean),
    };
  };

  return (
    <Typography sx={sx}>
      {comparedText.filter(change => change.value).length
        ? comparedText.map((ct, index) => {
            // eslint-disable-next-line react/no-array-index-key
            if (!ct.added && !ct.removed) return <Fragment key={index}>{ct.value}</Fragment>;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={index} {...getCommonProps(ct)}>
                {ct.value}
              </Typography>
            );
          })
        : '-'}
    </Typography>
  );
}
