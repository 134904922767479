import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement, ReactNode } from 'react';

const styles: Styles = {
  loading: { color: '#fff' },
};

interface ExtendedProps {
  loading?: boolean;
  icon?: ReactNode;
}

export function ButtonWithLoading({
  children,
  loading,
  icon,
  disabled,
  ...props
}: ButtonProps & ExtendedProps): ReactElement {
  return (
    <Button disabled={loading ? true : disabled} {...props}>
      {loading ? <CircularProgress size={20} sx={styles.loading} /> : icon}
      {children}
    </Button>
  );
}
