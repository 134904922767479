import { useLayoutEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export function usePathsMatch(paths: string[], initial = false): boolean {
  const location = useLocation();

  const [matched, setMatched] = useState<boolean>(initial);

  useLayoutEffect(() => {
    let flag = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const r of paths) {
      if (matchPath(r, location.pathname)) {
        flag = true;
        break;
      }
    }
    setMatched(flag);
  }, [location, paths]);

  return matched;
}
