import { CSSProperties, Children, ReactElement, ReactNode, cloneElement, isValidElement } from 'react';
import { useDemoContext } from '../demo-context';

const cssBlur = 'blur(5px)';

interface Props {
  children: ReactNode;
}

/**
 * Child should be either a text or support style in props
 */
export function DemoBlurWrapper({ children }: Props): ReactElement {
  const { active } = useDemoContext();

  const modifiedChildren = Children.map(children, child => {
    if (!active) return child;

    // components modifier
    if (isValidElement(child)) {
      return cloneElement(child, {
        style: { ...(child.props.style ?? {}), filter: cssBlur } as CSSProperties,
      } as never);
    }

    // text wrapper
    return <span style={{ filter: cssBlur }}>{child}</span>;
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{modifiedChildren}</>;
}
