/* eslint-disable react/no-danger */
import { ReactElement } from 'react';
import { Box, Button } from '@mui/material';
import { Styles } from 'common/types/styles';
import { PdpAttribute } from 'common/types/common';
import { tryJSONParse } from 'common/utils/json';
import { TruncateContainer } from 'common/ui/containers/truncate-container';
import toast from 'react-hot-toast';

const styles: Styles = {
  requestContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: 250 },
  copyBtn: { alignSelf: 'flex-end' },
};

interface Props {
  item: PdpAttribute;
}

export function PdpHistoryResponse({ item }: Props): ReactElement {
  const formatJson = (value: string) => {
    const jsonObject = tryJSONParse(value);

    if (!jsonObject) return null;

    let formattedString = '';

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in jsonObject) {
      formattedString += `${key}: ${jsonObject[key]}\n`;
    }

    return formattedString.replace(/procedure/gi, '<span style="font-weight: bold;">procedure</span>');
  };

  return (
    <Box sx={styles.requestContainer}>
      {formatJson(item?.response?.content) && (
        <Button
          onClick={() => {
            navigator.clipboard.writeText(formatJson(item?.response?.content));
            toast.success('Message copied!');
          }}
          sx={styles.copyBtn}
          size="small"
        >
          Copy
        </Button>
      )}
      <TruncateContainer maxHeight={70}>
        {item?.response?.content ? (
          <div dangerouslySetInnerHTML={{ __html: formatJson(item?.response?.content) ?? '-' }} />
        ) : (
          ''
        )}
      </TruncateContainer>
    </Box>
  );
}
