import { MouseEvent, ReactElement, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Styles } from 'common/types/styles';
import { PdpAttribute } from 'common/types/common';
import { usePdpTypesQuery } from 'common/hooks/api/queries/use-pdp-query';
import { formatMoney } from 'common/utils/numbers';
import { userService } from 'common/services/user.service';
import { RequestPopup } from './request-popup';
import { PdpHistoryResponse } from './pdp-history-response';
import { PdpHistoryRequest } from './pdp-history-request';

const styles: Styles = {
  descriptionColumn: { width: 250 },
  requestContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: 250 },
  copyBtn: { alignSelf: 'flex-end' },
};

interface Props {
  item: PdpAttribute;
}

export function PdpHistoryItem({ item }: Props): ReactElement {
  const { data: types } = usePdpTypesQuery();

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>(null);

  const isL3 = userService.ensureLevel('L3');

  const getTypeName = (type: string) => {
    return types?.find(item => item?.value === type)?.processDisplayName;
  };

  const handlePopupOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <TableRow>
        <TableCell>{item?.date}</TableCell>
        <TableCell>{item?.optimization_process ? getTypeName(item?.optimization_process) : '-'}</TableCell>
        <TableCell>{item?.model}</TableCell>
        {!isL3 && <TableCell>{item?.price ? formatMoney(item?.price, { minimumFractionDigits: 6 }) : '-'}</TableCell>}
        <TableCell>
          <PdpHistoryRequest item={item} handlePopupOpen={handlePopupOpen} />
        </TableCell>
        <TableCell sx={styles.descriptionColumn}>
          <PdpHistoryResponse item={item} />
        </TableCell>
      </TableRow>
      <RequestPopup anchorElement={anchorElement} onClose={handlePopupClose} data={item?.request?.messages} />
    </>
  );
}
