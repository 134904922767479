import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Styles } from 'common/types/styles';
import { theme } from 'common/constants/theme';

const styles: Styles = {
  container: { width: '100%', border: '1px solid #E9E9EB', ':before': { display: 'none' } },
  summary: { borderBottom: '1px solid #E9E9EB', padding: theme.spacing(1, 3) },
  details: { padding: 3 },
};

interface Props {
  children: ReactNode;
  title: string;
  defaultExpanded?: boolean;
}

export function StyledAccordion({ children, title, defaultExpanded }: Props): ReactElement {
  return (
    <Accordion disableGutters square sx={styles.container} defaultExpanded={defaultExpanded} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={styles.summary}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>{children}</AccordionDetails>
    </Accordion>
  );
}
