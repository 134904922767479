import { AxiosResponse } from 'axios';
import { ApiService } from '../api.service';
import {
  PdpPlaceholdersParams,
  PdpPlaceholdersResponse,
  PdpPromptParams,
  PdpTemplateBody,
  PdpTemplatesParams,
  PdpTemplatesResponse,
  PdpTypesParams,
  PdpTypesResponse,
  SelectPdpTemplateBody,
} from './pdp-api.types';

class PdpApiService extends ApiService {
  getPdpTypes = (retailer: string, args?: PdpTypesParams): Promise<AxiosResponse<PdpTypesResponse>> => {
    return this.get(`/attributes/generation/${retailer}/templates/pdp-optimization/types`, {
      params: {
        ...(args?.vendor ? { vendor: args?.vendor } : {}),
      },
    });
  };

  getPdpPlaceholders = (
    args: PdpPlaceholdersParams,
    retailer: string
  ): Promise<AxiosResponse<PdpPlaceholdersResponse>> => {
    return this.get(`/attributes/generation/${retailer}/templates/pdp-optimization/placeholders`, {
      params: {
        ...(args?.wpid ? { wpid: args?.wpid } : {}),
        ...(args?.tcin ? { tcin: args?.tcin } : {}),
        ...(args?.vendor ? { vendor: args?.vendor } : {}),
      },
    });
  };

  getPdpTemplates = (retailer: string, args: PdpTemplatesParams): Promise<AxiosResponse<PdpTemplatesResponse>> => {
    return this.get(`/attributes/generation/${retailer}/templates/pdp-optimization`, {
      params: {
        ...(args?.process ? { process: args?.process } : {}),
        ...(args?.vendor ? { vendor: args?.vendor } : {}),
      },
    });
  };

  getPdpPrompt = (args: PdpPromptParams): Promise<AxiosResponse> => {
    return this.get(`/attributes/generation/${args?.retailer}/templates/pdp-optimization/prompt`, {
      params: {
        ...(args?.templateId ? { templateId: args?.templateId } : {}),
        ...(args?.wpid ? { wpid: args?.wpid } : {}),
        ...(args?.tcin ? { tcin: args?.tcin } : {}),
        ...(args?.vendor ? { vendor: args?.vendor } : {}),
      },
    });
  };

  savePdpTemplate = (retailer: string, body: PdpTemplateBody): Promise<AxiosResponse> => {
    return this.post(`/attributes/generation/${retailer}/templates/pdp-optimization/save`, body);
  };

  selectPdpTemplate = (retailer: string, body: SelectPdpTemplateBody): Promise<AxiosResponse> => {
    return this.post(`/attributes/generation/${retailer}/templates/pdp-optimization/select`, body);
  };
}

export const pdpApi = new PdpApiService(process.env.REACT_APP_AI_API);
