import { useMutation } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { pdpApi } from 'common/services/api/pdp/pdp-api.service';
import { PdpTemplateBody, SelectPdpTemplateBody } from 'common/services/api/pdp/pdp-api.types';
import { userService } from 'common/services/user.service';

export function useSavePdpTemplateMutation() {
  const pdpMutation = useMutation({
    mutationFn: async (body: PdpTemplateBody) => {
      const apiRetailer = userService.getAPIRetailer();
      const retailer = userService.getRetailer();

      const otherTargetVendor =
        apiRetailer === RetailerType.Target && retailer === RetailerType.Autozone
          ? { vendor: retailer?.toLowerCase() }
          : {};

      return pdpApi.savePdpTemplate(apiRetailer?.toLowerCase(), { ...body, ...otherTargetVendor });
    },
  });

  return pdpMutation;
}

export function useSelectPdpTemplateMutation() {
  const pdpMutation = useMutation({
    mutationFn: async (body: SelectPdpTemplateBody) => {
      const apiRetailer = userService.getAPIRetailer();
      const retailer = userService.getRetailer();

      const otherTargetVendor =
        apiRetailer === RetailerType.Target && retailer === RetailerType.Autozone
          ? { vendor: retailer?.toLowerCase() }
          : {};

      return pdpApi.selectPdpTemplate(apiRetailer?.toLowerCase(), { ...body, ...otherTargetVendor });
    },
  });

  return pdpMutation;
}
