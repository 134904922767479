import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { attributesApi } from 'common/services/api/attributes/attributes-api.service';
import {
  GetAICategoryProductsParams,
  GetAIModelsParams,
  GetAttributeAITemplatesParams,
  GetSyncHistoryFilters,
  PreviewAIPromptParams,
} from 'common/services/api/attributes/attributes-api.types';
import { userService } from 'common/services/user.service';
import { AttributeHistoryEntry } from 'common/types/common';

export enum AttributesQueryKey {
  AttributesSuggestions = 'AttributesSuggestions',
  Mapping = 'Mapping',
  AIPreview = 'AIPreview',
  AttributesGroups = 'AttributesGroups',
  AIQueueStats = 'AIQueueStats',
  AIQueueStatsV3 = 'AIQueueStatsV3',
  AICategoryProducts = 'AICategoryProducts',
  AIModels = 'AIModels',
  AITemplates = 'AITemplates',
  ConditionalInstructions = 'ConditionalInstructions',
  ConditionalInstructionsFields = 'ConditionalInstructionsFields',
  SyncHistory = 'SyncHistory',
  Keywords = 'Keywords',
}

export function useAttributeSuggestionsQuery(categoryID: number | string, attribute: string, enabled: boolean) {
  const query = useQuery<Record<string, string[]>>({
    queryKey: [AttributesQueryKey.AttributesSuggestions, categoryID, attribute],
    enabled,
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const { data } = await attributesApi.getSuggestions(categoryID, attribute);
      return data.suggestions;
    },
  });
  return query;
}

export function useMappingQuery(enabled?: boolean) {
  const query = useQuery<Record<string, string[]>>({
    queryKey: [AttributesQueryKey.Mapping],
    queryFn: async () => {
      const { data } = await attributesApi.getMappings();
      return data.mapping;
    },
    enabled,
  });

  return query;
}

export function useAttributeAIPreviewQuery(params: PreviewAIPromptParams, enabled?: boolean) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIPreview, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.previewAI(retailer, params);
      return data.aiInput;
    },
    enabled,
  });

  return query;
}

export function useAttributesGroupsQuery(categoryID: number | string) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AttributesGroups, categoryID],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAttributesGroups(categoryID, retailer);
      return data.attributeGroups;
    },
    enabled: Boolean(categoryID),
  });

  return query;
}

export function useAIQueueStatsQuery() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIQueueStats],
    staleTime: 1000 * 10,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAIQueueStats(retailer);
      return data.stats;
    },
  });

  return query;
}

export function useAIQueueStatsQueryV3() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIQueueStatsV3],
    staleTime: 1000 * 10,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const subVendorID = userService.getSubRetailer();
      const { data } = await attributesApi.getAIQueueStatsV3(retailer, { sub_vendor_id: subVendorID });
      return data;
    },
  });

  return query;
}

export function useAICategoryProductsQuery(params: GetAICategoryProductsParams, enabled?: boolean) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AICategoryProducts, params],
    staleTime: 1000 * 10,
    enabled,
    queryFn: async () => {
      const { data } = await attributesApi.getAICategoryProducts(params);
      return data;
    },
  });

  return query;
}

export function useAIModelsQuery(params?: GetAIModelsParams) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIModels, params],
    queryFn: async () => {
      const { data } = await attributesApi.getAIModels(params);
      return data.models;
    },
  });

  return query;
}

export function useAttributeAITemplates(params: GetAttributeAITemplatesParams, enabled?: boolean) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AITemplates],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAttributeAITemplates(retailer, params);
      return data;
    },
    enabled,
  });

  return query;
}

export function useConditionalInstructionsQuery() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ConditionalInstructions],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getConditionalInstructions(retailer);
      return data;
    },
  });

  return query;
}

export function useConditionalInstructionsFieldsQuery() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ConditionalInstructionsFields],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getConditionalInstructionsFields(retailer);
      return data;
    },
  });

  return query;
}

export function useSyncAttributesHistoryQuery(filters?: GetSyncHistoryFilters) {
  const query = useInfiniteQuery({
    queryKey: [AttributesQueryKey.SyncHistory, filters],
    getNextPageParam: (lastPage: AttributeHistoryEntry[]) => {
      return lastPage.length ? lastPage[lastPage.length - 1].date : undefined;
    },
    queryFn: async ctx => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getSyncHistory({ ...filters, cursorNext: ctx.pageParam }, retailer);
      return data;
    },
  });

  return query;
}

export function useKeywordsQuery(pid: string) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.Keywords, pid],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getKeywords(retailer, pid);
      return data;
    },
  });

  return query;
}
