import { Box, IconButton } from '@mui/material';
import { BackendError } from 'common/types/common';
import { Styles } from 'common/types/styles';
import { ReactElement } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { theme } from 'common/constants/theme';

const styles: Styles = {
  container: { display: 'flex', alignItems: 'center', gap: '2px' },
  btn: {
    borderColor: theme.palette.grey[700],
    color: theme.palette.grey[700],
    fontSize: 12,
    ':hover': { borderColor: theme.palette.grey[700], backgroundColor: theme.palette.grey[100] },
  },
};

interface Props {
  error: BackendError;
}

export function BackendErrorToast({ error }: Props): ReactElement {
  return (
    <Box sx={styles.container}>
      {error.safeMessage}
      <IconButton
        sx={styles.btn}
        color="info"
        onClick={() => navigator.clipboard.writeText(`Error - ${error.safeMessage}; traceID - ${error.traceID} `)}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}
