import { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { ApiService } from 'common/services';
import { ExtendedContentEntry, TemplateRule } from 'common/types/common';
import { PocRetailers, RetailerType, selectAllValue } from 'common/constants/entities';
import { userService } from 'common/services/user.service';
import {
  EnsureProductBody,
  ExportProductsCsvBody,
  ResetValueBody,
  SaveScrapedAttributesData,
  SuggestCategoriesData,
  TargetProductsFilters,
  TargetProductsListResponse,
} from './products-api.types';

function getTags(search: Array<string>) {
  const t = search
    .filter(s => s.includes(':'))
    .join(',')
    .trim();
  if (!t.length) return undefined;
  return t;
}
function getTCINs(search: Array<string>) {
  const ids =
    !userService.ensureRetailers(PocRetailers) &&
    !userService.ensureRetailer(RetailerType.Target) &&
    !userService.ensureRetailer(RetailerType.Autozone)
      ? search.filter(s => !s.includes(':') && Number.isInteger(Number(s)))
      : search.filter(s => !s.includes(':'));

  if (!ids.length) return undefined;
  return ids;
}

function parseProductsFilters(args?: TargetProductsFilters) {
  if (!args) return undefined;

  return {
    limit: args.limit,
    offset: args.offset,
    ...(args.scope_ids?.length && !args.scope_ids?.includes(selectAllValue)
      ? { scope_ids: args.scope_ids.join(',') }
      : {}),
    ...(args.category_id ? { category_id: args.category_id } : {}),
    ...(args.assigned_users?.length ? { assigned_users: args.assigned_users.join(',') } : {}),
    ...(args.approved_by?.length ? { approved_by: args.approved_by.join(',') } : {}),
    ...(args.actor_ids?.length ? { actor_ids: args.actor_ids.join(',') } : {}),
    ...(args.statuses?.length ? { statuses: args.statuses.join(',') } : {}),
    ...(args.content !== undefined ? { content: true } : {}),
    ...(args.skip_level !== undefined ? { skip_level: args.skip_level } : {}),
    ...(args.calc_attributes !== undefined ? { calc_attributes: args.calc_attributes } : {}),
    ...(args.tcins !== undefined ? { tcins: args.tcins.length ? args.tcins : '' } : {}),
    ...(args.categoryIDs?.length ? { category_ids: args.categoryIDs } : {}),
    ...(args.sub_vendor_id !== undefined ? { sub_vendor_id: args.sub_vendor_id } : {}),
    ...(args.flagged !== undefined ? { flagged: args.flagged } : {}),
    ...(args.import_id !== undefined ? { import_id: args.import_id } : {}),
    ...(args.search?.length
      ? {
          tags: getTags(args.search),
          tcins: getTCINs(args.search),
        }
      : {}),
  };
}

class ProductsApiService extends ApiService {
  getProducts = (
    retailer: RetailerType,
    args?: TargetProductsFilters
  ): Promise<AxiosResponse<TargetProductsListResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products`, {
      params: parseProductsFilters(args),
    });
  };

  getProductById = (retailer: RetailerType, id: string): Promise<AxiosResponse<{ product: ExtendedContentEntry }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products/${id}/content`);
  };

  getRetailerAttributes = (
    retailer: RetailerType,
    id: number | string
  ): Promise<AxiosResponse<{ attributes: Record<string, TemplateRule> }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/categories/${id}/attributes`);
  };

  resetValue = (filters: ResetValueBody, retailer: string) => {
    return this.post(`/vendors/${retailer}/products/reset`, filters);
  };

  exportTargetCsv = (filters: ExportProductsCsvBody, retailer: string) => {
    return this.post(`/vendors/${retailer}/products/export`, filters);
  };

  exportAmazonProducts = (filters: ExportProductsCsvBody) => {
    return this.post('/vendors/poc/products/amazon-export', filters);
  };

  performWalmartOCR = (pids: Array<string>) => {
    return this.post('/content/ocr', { pids });
  };

  performTargetOCR = (tcins: Array<string>) => {
    return this.post('/vendors/target/products/ocr', { tcins });
  };

  suggestCategories = (data: SuggestCategoriesData): Promise<AxiosResponse<{ id: string }>> => {
    const formData = new FormData();
    data.photos.forEach(p => {
      formData.append('files', p);
    });
    return this.post(`/vendors/${data.retailer.toLowerCase()}/products/categories-suggestions`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  ensureProduct = (retailer: string, body: EnsureProductBody): Promise<AxiosResponse<{ id: string }>> => {
    return this.post(`/vendors/${retailer}/products/ensure-product`, omit(body, 'retailer'));
  };

  downloadCSV = (retailer: string, tcin: string): Promise<AxiosResponse<string>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${tcin}/download-csv`);
  };

  saveScrapedAttributesData = (retailer: RetailerType, data: SaveScrapedAttributesData) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${data.tcin}/external-content`, data);
  };

  updateProductCategory = (retailer: RetailerType, tcin: string, categoryId: string) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${tcin}/category`, { category_id: categoryId });
  };

  addImagesXlsx = (retailer: string, files: Array<File>) => {
    const formData = new FormData();
    files.forEach(p => {
      formData.append('file', p);
    });

    return this.post(`/vendors/${retailer.toLowerCase()}/products/images-xlsx`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };
}

export const productsApi = new ProductsApiService(process.env.REACT_APP_AI_API);
