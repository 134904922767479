import { ReactNode, useEffect, useRef, useState } from 'react';
import { Fade, Button, Box } from '@mui/material';
import { Styles } from 'common/types/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { theme } from 'common/constants/theme';
import { SystemStyleObject } from '@mui/system';

const getStyles = (truncateColor: 'grey' | 'white'): Styles => ({
  fadeContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '30px',
    background: `linear-gradient(rgba(${truncateColor === 'grey' ? '250,250,250' : '255,255,255'}, 0), rgba(${
      truncateColor === 'grey' ? '250,250,250' : '255,255,255'
    }, 1))`,
  },
  btn: { fontSize: 11, height: 20, ml: 'auto', color: theme.palette.grey[600] },
});

interface Props {
  children: ReactNode;
  maxHeight: number;
  truncateColor?: 'grey' | 'white';
  sx?: SystemStyleObject;
}

export function TruncateContainer({ children, maxHeight, sx, truncateColor = 'grey' }: Props) {
  const [truncated, setTruncated] = useState<boolean>(true);
  const [enabled, setEnabled] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const styles = getStyles(truncateColor);

  const toggleTruncate = () => {
    setTruncated(!truncated);
  };

  useEffect(() => {
    if (contentRef.current) {
      setTruncated(contentRef.current?.scrollHeight > maxHeight);
      setEnabled(contentRef.current?.scrollHeight > maxHeight);
    }
  }, [maxHeight]);

  return (
    <Box sx={sx}>
      <Box
        ref={contentRef}
        width="inherit"
        maxWidth="inherit"
        maxHeight={truncated ? `${maxHeight}px` : 'none'}
        overflow="hidden"
        position="relative"
      >
        {children}
        <Fade in={truncated}>
          <Box sx={styles.fadeContainer} />
        </Fade>
      </Box>
      {enabled && (
        <Button size="small" onClick={toggleTruncate} color="inherit" sx={styles.btn}>
          {truncated ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowUpIcon fontSize="small" />}
          {truncated ? 'Show More' : 'Show Less'}
        </Button>
      )}
    </Box>
  );
}
