import { useAuth0 } from '@auth0/auth0-react';
import { Box, IconButton } from '@mui/material';
import { Styles } from 'common/types/styles';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { ReactElement } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAvatarStyles } from 'common/utils/styles';
import { useMeQuery } from 'common/hooks/api/queries/use-users-query';
import PreviewIcon from '@mui/icons-material/Preview';
import { useDemoContext } from 'common/ui/containers/demo-context';
import { userService } from 'common/services/user.service';

const styles: Styles = {
  userContainer: { display: 'flex', alignItems: 'center', gap: 1.5 },
};

export function AuthUserControls(): ReactElement {
  const { user, logout } = useAuth0();
  const { active, setActive } = useDemoContext();

  const { data: meData } = useMeQuery();

  return (
    <Box sx={styles.userContainer}>
      <LightTooltip title={user?.name} placement="bottom">
        <Box sx={getAvatarStyles(meData?.user.picture, 36)} />
      </LightTooltip>
      {userService.isAdmin() && (
        <LightTooltip title="Demo Mode">
          <IconButton color={active ? 'primary' : 'default'} onClick={() => setActive(!active)}>
            <PreviewIcon fontSize="small" />
          </IconButton>
        </LightTooltip>
      )}
      <LightTooltip title="Logout" placement="bottom">
        <IconButton onClick={() => logout({ returnTo: window.location.origin })}>
          <LogoutIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    </Box>
  );
}
