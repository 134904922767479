import { useEffect } from 'react';
import Smartlook from 'smartlook-client';

const useSmartLook = () => {
  useEffect(() => {
    Smartlook.init(process.env.REACT_APP_SMARTLOOK_KEY, { region: 'eu' });
  }, []);
};

export default useSmartLook;
