import { SystemStyleObject } from '@mui/system';
import { theme } from 'common/constants/theme';

export const getCenteredImageStyles = (image: string, size: number): SystemStyleObject => ({
  width: size,
  height: size,
  minWidth: size,
  backgroundColor: theme.palette.grey[300],
  backgroundImage: `url("${image}")`,
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
});

export const getAvatarStyles = (image: string, size: number): SystemStyleObject => ({
  ...getCenteredImageStyles(image, size),
  borderRadius: '50%',
});
