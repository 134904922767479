import { Box, Switch, Typography } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import { SEOCompareSource } from 'modules/common/product-details-page/constants';
import { getBTNsStyles } from './styles';

interface Props {
  value: SEOCompareSource;
  handleChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export function SEOCompareSourceToggles({ value, handleChange }: Props): ReactElement {
  const styles = getBTNsStyles();

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.toggle}>ORIGINAL</Typography>
      <Switch checked={value === SEOCompareSource.SEO} onChange={handleChange} color="primary" />
      <Typography sx={styles.toggle}>SEO</Typography>
    </Box>
  );
}
