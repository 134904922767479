export const tryJSONParse = (json: string) => {
  let obj;

  try {
    obj = JSON.parse(json);
    // eslint-disable-next-line no-empty
  } catch {}

  return obj ?? null;
};
