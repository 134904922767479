import { MouseEvent, ReactElement } from 'react';
import { Box, Button } from '@mui/material';
import { Styles } from 'common/types/styles';
import { PdpAttribute } from 'common/types/common';
import { TruncateContainer } from 'common/ui/containers/truncate-container';
import toast from 'react-hot-toast';

const styles: Styles = {
  requestContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: 250 },
  copyBtn: { alignSelf: 'flex-end' },
};

interface Props {
  item: PdpAttribute;
  handlePopupOpen: (e: MouseEvent<HTMLButtonElement>) => void;
}

export function PdpHistoryRequest({ item, handlePopupOpen }: Props): ReactElement {
  return (
    <div>
      {item.model.includes('gemini') ? (
        <Box sx={styles.requestContainer}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(item?.request ?? ''));
              toast.success('Message copied!');
            }}
            sx={styles.copyBtn}
            size="small"
          >
            Copy
          </Button>
          <TruncateContainer maxHeight={70}>{JSON.stringify(item?.request ?? '')}</TruncateContainer>
        </Box>
      ) : (
        <Button color="primary" variant="contained" onClick={handlePopupOpen}>
          Preview
        </Button>
      )}
    </div>
  );
}
