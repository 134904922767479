import {
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { theme } from 'common/constants/theme';
import { useProductIssues } from 'common/hooks/use-product-issues';
import { ExtendedContentEntry } from 'common/types/common';
import { Styles } from 'common/types/styles';
import { TruncateContainer } from 'common/ui/containers/truncate-container';
import { StatusDropdown } from 'common/ui/inputs/status-dropdown';
import { ReactElement, ReactNode } from 'react';

enum ExtendedColumn {
  Score = 'Score',
  Status = 'Status',
  Details = 'Details',
}

interface StylesProps {
  width: number;
}

const getStyles = ({ width }: StylesProps): Styles => ({
  container: {
    width,
    height: 280,
    padding: 2,
    boxSizing: 'border-box',
  },
  noIssues: { fontSize: 12, color: theme.palette.grey[700], margin: '0 auto', display: 'block', width: 'max-content' },
});

interface Props {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  product: ExtendedContentEntry;
  attributeKeys: Array<string>;
  extendedColumns?: Array<keyof typeof ExtendedColumn>;
  extendedSize?: boolean;
}

export function IssuesPopup({
  anchorElement,
  onClose,
  product,
  attributeKeys,
  extendedColumns,
  extendedSize = false,
}: Props): ReactElement {
  const styles = getStyles({ width: extendedSize ? 960 : 400 });

  const { issues } = useProductIssues({ product, attributeKeys });

  const renderExtendedColumn = (column: keyof typeof ExtendedColumn, issueIndex: number) => {
    let v: ReactNode;

    switch (column) {
      case ExtendedColumn.Status:
        v = <StatusDropdown value={issues[issueIndex].resolved ? 'Resolved' : 'Pending'} showExpand={false} />;
        break;
      case ExtendedColumn.Score:
        v = issues[issueIndex].score ?? '-';
        break;
      case ExtendedColumn.Details:
        const comments = issues[issueIndex].detailedIssue?.issueComments;
        v = comments?.length ? (
          <TruncateContainer maxHeight={40}>
            {comments.map(c => (
              <Typography key={c} fontSize={14}>
                {c}
              </Typography>
            ))}
          </TruncateContainer>
        ) : (
          '-'
        );
        break;
      default:
        return null;
    }

    return <TableCell key={column}>{v}</TableCell>;
  };

  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={styles.container}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Rule</TableCell>
                {extendedColumns?.map(ec => (
                  <TableCell key={ec}>{ec}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {issues.map((issue, index) => (
                <TableRow key={`${issue.issuePrettyName}-${issue.attribute}`}>
                  <TableCell>{issue.issuePrettyName}</TableCell>
                  <TableCell>{issue.issueRule}</TableCell>
                  {extendedColumns.map(ec => renderExtendedColumn(ec, index))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!issues.length && <Typography sx={styles.noIssues}>There are no issues for this attribute</Typography>}
        </TableContainer>
      </Box>
    </Popover>
  );
}
