import { ReactElement, useMemo } from 'react';
import { useAIModelsQuery } from 'common/hooks/api/queries/use-attributes-query';
import { SystemStyleObject } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { AIModel } from 'common/types/common';
import { SelectField, SelectFieldOption } from '../select-field';

const prepareOptionInfo = (model: AIModel) => {
  let out = '';

  out += `input price: ${model.price.input}`;
  out += `\noutput price: ${model.price.output}`;

  Object.entries(model.additionalInfo ?? {}).forEach(([key, value]) => {
    out += `\n${key}: ${value}`;
  });

  return out;
};

interface Props {
  sx?: SystemStyleObject;
  onChange: (value: string) => void;
  value: string;
  label?: string;
  process?: 'initial_generation' | 'ai_validation' | 'ai_optimization';
}

export function AIModelsSelect({ sx, onChange, value, label, process = 'initial_generation' }: Props): ReactElement {
  const { data } = useAIModelsQuery({ process });

  const options: Array<SelectFieldOption> = useMemo(() => {
    return (
      data?.map(m => ({
        label: m.displayName ?? m.name,
        value: m.name,
        icon: (
          <LightTooltip title={<pre style={{ whiteSpace: 'pre-wrap' }}>{prepareOptionInfo(m)}</pre>}>
            <InfoIcon fontSize="small" />
          </LightTooltip>
        ),
      })) ?? []
    );
  }, [data]);

  return <SelectField options={options} value={value} onChange={onChange} sx={sx} label={label} />;
}
