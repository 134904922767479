import { Box, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { MXAILogo } from 'common/ui/shared/mxai-logo';
import { getCookie, setCookie } from 'common/utils/cookies';

const retriesCookieKey = 'runtime_err_retries';

const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  logoContainer: {
    position: 'absolute',
    top: 60,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
};

export function RuntimeError() {
  const retries = getCookie(retriesCookieKey);

  const reload = (): null => {
    setCookie(retriesCookieKey, String(Number(retries ?? 0) + 1), 10 * 1000);
    window.location.reload();
    return null;
  };

  if ((!retries || Number(retries) < 3) && process.env.NODE_ENV !== 'development') return reload();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.logoContainer}>
        <MXAILogo />
      </Box>
      <Typography variant="h3" color="GrayText">
        Something went wrong. Please, reload the page.
      </Typography>
    </Box>
  );
}
