import { ReactElement } from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { IconButton, IconButtonProps } from '@mui/material';
import { LightTooltip } from 'common/ui/containers/light-tooltip';

export function AttributeEnhanceButton(props: IconButtonProps): ReactElement {
  return (
    <LightTooltip title="Enhance Attribute">
      <IconButton {...props}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <AutoFixHighIcon fontSize={props.size} />
      </IconButton>
    </LightTooltip>
  );
}
