import { Box, Switch, Typography } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import { getBTNsStyles } from './styles';
import { OptimizationStatus } from '../../constants';

interface Props {
  optimizationStatus: OptimizationStatus;
  handleChangeOptimizedStatus: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export function ProductToggles({ optimizationStatus, handleChangeOptimizedStatus }: Props): ReactElement {
  const styles = getBTNsStyles();

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.toggle}>REGULAR AI</Typography>
      <Switch
        checked={optimizationStatus === OptimizationStatus.SEO}
        onChange={handleChangeOptimizedStatus}
        color="primary"
      />
      <Typography sx={styles.toggle}>SEO AI</Typography>
    </Box>
  );
}
