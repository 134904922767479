import { ReactElement, useMemo } from 'react';
import { transformAttributesFromBackend } from 'common/services/attributes.service';
import { useSelectedCategoryQuery, useSelectedProductQuery } from '../../hooks/queries';
import { DetailsTabValue } from '../../constants';
import { SpecsAccordion } from './specs-accordion';

interface Props {
  source: DetailsTabValue;
}

export function ProductSpecs({ source }: Props): ReactElement {
  const { data: productData } = useSelectedProductQuery();
  const { data: categoryData } = useSelectedCategoryQuery();

  const specs = useMemo(() => {
    if (!productData || !categoryData) return [];

    return transformAttributesFromBackend({
      category: categoryData,
      product: productData,
    });
  }, [productData, categoryData]);

  const oosSpecs = useMemo(() => {
    return specs.filter(s => !s.rules.settings.in_scope);
  }, [specs]);

  const inScopeSpecs = useMemo(() => {
    return specs.filter(s => s.rules.settings.in_scope);
  }, [specs]);

  return (
    <>
      <SpecsAccordion title="Specifications" specs={inScopeSpecs} source={source} defaultExpanded />
      <SpecsAccordion title="Additional Attributes" specs={oosSpecs} source={source} defaultExpanded={false} />
    </>
  );
}
