import { useQuery } from '@tanstack/react-query';
import { RetailerType, SyndigoRetailers } from 'common/constants/entities';
import { categoryApi } from 'common/services/api/category/category-api.service';
import { CategoriesSearchFilters } from 'common/services/api/category/category-api.types';
import { productsApi } from 'common/services/api/products/products-api.service';
import { userService } from 'common/services/user.service';
import { TemplateRule } from 'common/types/common';

export enum CategoryQueryKey {
  Category = 'Category',
  CategoriesSearch = 'CategoriesSearch',
  BenchmarkCategories = 'BenchmarkCategories',
}

interface UseCategoryProps {
  id: number | string;
  enabled?: boolean;
  retailer?: RetailerType;
}

export function useCategoryQuery({ id, enabled, retailer }: UseCategoryProps) {
  const query = useQuery<Record<string, TemplateRule>>({
    queryKey: [CategoryQueryKey.Category, id],
    enabled: Boolean(id && enabled),
    staleTime: 1000 * 10,
    queryFn: async () => {
      const currRetailer =
        userService.ensureRetailers(SyndigoRetailers) || userService.getRetailer() === RetailerType.Autozone
          ? RetailerType.Target
          : (userService.getAPIRetailer() as RetailerType);
      const apiRetailer = retailer || currRetailer;
      const { data } =
        apiRetailer === RetailerType.Walmart
          ? await categoryApi.getCategory(id)
          : await productsApi.getRetailerAttributes(apiRetailer, id);
      return data.attributes;
    },
  });
  return query;
}

export function useCategoriesSearchQuery(filters: CategoriesSearchFilters, enabled?: boolean) {
  const query = useQuery({
    queryKey: [CategoryQueryKey.CategoriesSearch, filters],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await categoryApi.searchWalmartCategories(filters)
        : await categoryApi.searchCategories(retailer, filters);
      return data;
    },
    enabled,
  });

  return query;
}

export function useBenchmarkCategoriesQuery() {
  const query = useQuery({
    queryKey: [CategoryQueryKey.BenchmarkCategories],
    queryFn: async () => {
      const { data } = await categoryApi.getBenchmarkCategories();
      return data;
    },
  });

  return query;
}
