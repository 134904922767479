import { ListItemIcon, ListItemText, MenuItem, TextField, TextFieldProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { ChangeEvent } from 'react';

export interface SelectFieldOption {
  value: string | number;
  label: string;
  icon?: React.ReactNode;
}

const cssBlur = 'blur(5px)';

const styles: Styles = {
  select: {
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  optionText: {
    fontSize: '12px',
  },
  selectedOptionText: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  optionIcon: {
    marginRight: theme.spacing(1),
    minWidth: 'unset !important',
  },
  selectedOptionIcon: {
    minWidth: 'unset !important',
    marginRight: theme.spacing(1),
    color: '#000',
  },
  listItemText: { flex: 'unset' },
};

interface Props<T> {
  options: SelectFieldOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: T) => void;
  error?: string;
  multiple?: boolean;
  sx?: SxProps<Theme>;
  selectSx?: SxProps<Theme>;
  label?: string;
  disabled?: boolean;
  variant?: TextFieldProps['variant'];
  placeholder?: string;
  fullWidth?: boolean;
  demoBlur?: boolean;
}

export function SelectField<T = unknown>({
  options,
  value,
  onChange,
  sx,
  selectSx,
  error,
  multiple = false,
  label,
  disabled = false,
  variant,
  fullWidth,
  demoBlur = false,
  placeholder,
}: Props<T>) {
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as string;
    onChange(newValue as never);
  };

  const handleIsOptionSelected = (optionValue: string | number) => {
    return Array.isArray(value) ? value.some(item => item === optionValue) : value === optionValue;
  };

  return (
    <TextField
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={value as any}
      SelectProps={{ multiple: Array.isArray(value) && multiple, sx: selectSx }}
      onChange={handleValueChange}
      error={Boolean(error)}
      size="small"
      sx={[styles.select, ...(Array.isArray(sx) ? sx : [sx])]}
      helperText={error}
      label={label}
      disabled={disabled}
      variant={variant}
      fullWidth={fullWidth}
      placeholder={placeholder}
      select
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ ...styles.selectedOptionText, ...(demoBlur && { filter: cssBlur }) }}
        >
          {option.icon && (
            <ListItemIcon sx={handleIsOptionSelected(option.value) ? styles.selectedOptionIcon : styles.optionIcon}>
              {option.icon}
            </ListItemIcon>
          )}
          <ListItemText
            sx={styles.listItemText}
            primary={option.label}
            primaryTypographyProps={{
              sx: handleIsOptionSelected(option.value) ? styles.selectedOptionText : styles.optionText,
            }}
          />
        </MenuItem>
      ))}
    </TextField>
  );
}
