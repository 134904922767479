import { userService } from 'common/services/user.service';
import React, { FunctionComponent } from 'react';
import ProductDetailsPage from 'modules/common/product-details-page';
import { RetailerType } from './entities';
import { RoutesAvailability } from './routes-availability';

export interface Route {
  path: string;
  preview: string;
  component: FunctionComponent;
  isAvailable: boolean;
  hiddenLayout?: boolean;
}

const TemplateEditor = React.lazy(() => import('modules/walmart/templates-module/components/pages/template-editor'));
const TemplatesList = React.lazy(() => import('modules/walmart/templates-module/components/pages/templates-list'));
const ContentList = React.lazy(() => import('modules/common/content/components/pages/content-list'));
const ContentReview = React.lazy(() => import('modules/common/content/components/pages/content-review'));
const QuickReview = React.lazy(() => import('modules/common/content/components/pages/quick-review'));
// const Dashboard = React.lazy(() => import('modules/dashboard'));
const QuickStart = React.lazy(() => import('modules/common/quick-start'));
const Settings = React.lazy(() => import('modules/common/settings'));
const VerifyInvitation = React.lazy(() => import('modules/common/verify-invitation'));
const Overview = React.lazy(() => import('modules/walmart/overview'));
const Comments = React.lazy(() => import('modules/walmart/comments'));
const TargetComments = React.lazy(() => import('modules/target/comments'));
// const AIQueue = React.lazy(() => import('modules/common/ai/components/pages/ai-queue'));
const AIQueueV3 = React.lazy(() => import('modules/common/ai/components/pages/ai-queue-v3'));
const AIBenchmark = React.lazy(() => import('modules/common/ai/components/pages/ai-benchmark'));
const TeamsBenchmark = React.lazy(() => import('modules/common/ai/components/pages/teams-benchmark'));
const TeamsBenchmarkStats = React.lazy(() => import('modules/common/ai/components/pages/teams-benchmark-stats'));
const AIBenchmarkStats = React.lazy(() => import('modules/common/ai/components/pages/ai-benchmark-stats'));
const AIConditionalInstructions = React.lazy(
  () => import('modules/common/ai/components/pages/ai-conditional-instructions')
);
const ImageScoring = React.lazy(() => import('modules/common/ai/components/pages/image-scoring'));
const SwatchCreator = React.lazy(() => import('modules/common/ai/components/pages/swatch-creator'));
const BackgroundCreator = React.lazy(() => import('modules/common/ai/components/pages/background-creator'));
const CategoriesSettings = React.lazy(() => import('modules/common/categories-settings'));
const AISearch = React.lazy(() => import('modules/common/ai-search'));
const AddTcins = React.lazy(() => import('modules/target/products-enrichment/components/pages/add-tcins'));
const RulesHistory = React.lazy(() => import('modules/target/products-enrichment/components/pages/rules-history'));
const WalmartRulesHistory = React.lazy(() => import('modules/walmart/products-enrichment/pages/rules-history'));
const AddSkus = React.lazy(() => import('modules/walmart/products-enrichment/pages/add-wpids'));
const ProductDetailsAI = React.lazy(() => import('modules/common/product-details-ai'));
const Leaderboard = React.lazy(() => import('modules/common/leaderboard'));
const SanitizePreview = React.lazy(() => import('modules/common/sanitize-preview'));

const isL3Walmart = () => userService.ensureRetailer(RetailerType.Walmart) && userService.ensureLevel('L3');

export const getProtectedRoutes = (): Array<Route> => [
  {
    path: '/',
    preview:
      userService.ensureRetailer(RetailerType.Walmart) && !userService.ensureLevel('L3') ? 'Overview' : 'Products',
    component:
      userService.ensureRetailer(RetailerType.Walmart) && !userService.ensureLevel('L3') ? Overview : ContentList,
    isAvailable: RoutesAvailability.Overview(),
  },
  {
    path: '/content',
    preview: isL3Walmart ? 'Products' : 'Content Management',
    component: ContentList,
    isAvailable: RoutesAvailability.ContentManagement(),
  },
  {
    path: '/content/:pid',
    preview: 'Content Review',
    component: ContentReview,
    isAvailable: RoutesAvailability.ContentReview(),
  },
  {
    path: '/product/:pid',
    preview: 'Content Review',
    component: ContentReview,
    isAvailable: RoutesAvailability.ProductReview(),
  },
  {
    path: '/pdp/:pid',
    preview: 'Product Details',
    component: ProductDetailsPage,
    isAvailable: RoutesAvailability.PDP(),
  },
  {
    path: '/content/quick-review',
    preview: 'Quick Review',
    component: QuickReview,
    isAvailable: RoutesAvailability.QuickReview(),
    hiddenLayout: true,
  },
  {
    path: '/start',
    preview: 'Quick Start',
    component: QuickStart,
    isAvailable: RoutesAvailability.QuickStart(),
    hiddenLayout: !userService.ensureLevel('L3'),
  },
  {
    path: '/templates',
    preview: 'Templates Management',
    component: TemplatesList,
    isAvailable: RoutesAvailability.TemplatesManagement(),
  },
  {
    path: '/templates/:id',
    preview: 'Template Edit',
    component: TemplateEditor,
    isAvailable: RoutesAvailability.TemplateEditor(),
  },
  {
    path: '/support',
    preview: 'Support',
    component: () => <div>Support</div>,
    isAvailable: RoutesAvailability.Support(),
  },
  {
    path: '/settings/:nav',
    preview: 'Settings',
    component: Settings,
    isAvailable: RoutesAvailability.Settings(),
  },
  {
    path: '/comments',
    preview: 'Comments',
    component: userService.ensureRetailer(RetailerType.Walmart) ? Comments : TargetComments,
    isAvailable: RoutesAvailability.Comments(),
  },
  {
    path: '/ai-queue',
    preview: 'AI Queue',
    component: AIQueueV3,
    isAvailable: RoutesAvailability.AIQueue(),
  },
  {
    path: '/ai-instructions',
    preview: 'AI Conditional Instructions',
    component: AIConditionalInstructions,
    isAvailable: RoutesAvailability.AIInstructions(),
  },
  {
    path: '/ai-benchmark',
    preview: 'AI Benchmark',
    component: AIBenchmark,
    isAvailable: RoutesAvailability.AIBenchmark(),
  },
  {
    path: '/compare-benchmark',
    preview: 'Compare Benchmark',
    component: TeamsBenchmark,
    isAvailable: RoutesAvailability.CompareBenchmark(),
  },
  {
    path: '/compare-benchmark-stats',
    preview: 'Compare Benchmark Stats',
    component: TeamsBenchmarkStats,
    isAvailable: RoutesAvailability.CompareBenchmarkStats(),
  },
  {
    path: '/ai-benchmark-stats',
    preview: 'AI Benchmark Stats',
    component: AIBenchmarkStats,
    isAvailable: RoutesAvailability.AIBenchmarkStats(),
  },
  {
    path: '/categories-settings',
    preview: 'Categories Settings',
    component: CategoriesSettings,
    isAvailable: RoutesAvailability.CategoriesSettings(),
  },
  {
    path: '/add-tcins',
    preview: 'Add TCINs',
    component: AddTcins,
    isAvailable: RoutesAvailability.AddTCINs(),
  },
  {
    path: '/add-asins',
    preview: 'Add ASINs',
    component: AddTcins,
    isAvailable: RoutesAvailability.AddASINs(),
  },
  {
    path: '/add-xlsx',
    preview: 'Add XLSX',
    component: AddTcins,
    isAvailable: RoutesAvailability.AddXLSX(),
  },
  {
    path: '/change-tracking',
    preview: userService.ensureRetailer(RetailerType.Walmart) ? 'Walmart Change Tracking' : 'Target Change Tracking',
    component: userService.ensureRetailer(RetailerType.Walmart) ? WalmartRulesHistory : RulesHistory,
    isAvailable: RoutesAvailability.ChangeTracking(),
  },
  {
    path: '/add-skus',
    preview: 'Add SKUs',
    component: userService.ensureRetailer(RetailerType.Flywheel) ? AddTcins : AddSkus,
    isAvailable: RoutesAvailability.AddSKUs(),
  },
  {
    path: '/search',
    preview: 'AI Search',
    component: AISearch,
    isAvailable: RoutesAvailability.AISearch(),
    hiddenLayout: true,
  },
  {
    path: '/image-scoring',
    preview: 'Image Scoring',
    component: ImageScoring,
    isAvailable: RoutesAvailability.ImageScoring(),
  },
  {
    path: '/swatch-creator',
    preview: 'Swatch Creator',
    component: SwatchCreator,
    isAvailable: RoutesAvailability.SwatchCreator(),
  },
  {
    path: '/background-creator',
    preview: 'Background Creator',
    component: BackgroundCreator,
    isAvailable: RoutesAvailability.BackgroundCreator(),
    hiddenLayout: true,
  },
  {
    path: '/leaderboard',
    preview: 'Leaderboard',
    component: Leaderboard,
    isAvailable: RoutesAvailability.Leaderboard(),
  },
  {
    path: '/sanitize/:op_id',
    preview: 'Sanitize Preview',
    component: SanitizePreview,
    isAvailable: RoutesAvailability.SanitizePreview(),
  },
];

export const getUnProtectedRoutes = (): Array<Route> => [
  {
    path: '/invitation',
    preview: 'Verify Invitation',
    component: VerifyInvitation,
    isAvailable: true,
  },
  {
    path: '/pdp-gen',
    preview: 'Product Details Generator',
    component: ProductDetailsAI,
    isAvailable: true,
  },
];
