import { useQuery } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { pdpApi } from 'common/services/api/pdp/pdp-api.service';
import { PdpPlaceholdersParams, PdpTemplatesParams } from 'common/services/api/pdp/pdp-api.types';
import { userService } from 'common/services/user.service';

export enum PdpQueryKey {
  PdpTypes = 'PdpTypes',
  PdpPlaceholders = 'PdpPlaceholders',
  PdpTemplate = 'PdpTemplate',
}

export function usePdpTypesQuery() {
  const query = useQuery({
    queryKey: [PdpQueryKey.PdpTypes],
    queryFn: async () => {
      const apiRetailer = userService.getAPIRetailer();
      const retailer = userService.getRetailer();

      const otherTargetVendor =
        apiRetailer === RetailerType.Target && retailer === RetailerType.Autozone
          ? { vendor: retailer?.toLowerCase() }
          : {};

      const { data } = await pdpApi.getPdpTypes(apiRetailer?.toLowerCase(), otherTargetVendor);
      return data.types;
    },
  });

  return query;
}

export function usePdpPlaceholdersQuery(args: PdpPlaceholdersParams) {
  const query = useQuery({
    queryKey: [PdpQueryKey.PdpPlaceholders],
    queryFn: async () => {
      const apiRetailer = userService.getAPIRetailer();
      const retailer = userService.getRetailer();

      const otherTargetVendor =
        apiRetailer === RetailerType.Target && retailer === RetailerType.Autozone
          ? { vendor: retailer?.toLowerCase() }
          : {};

      const { data } = await pdpApi.getPdpPlaceholders({ ...args, ...otherTargetVendor }, apiRetailer?.toLowerCase());
      return data.placeholders;
    },
  });

  return query;
}

export function usePdpTemplatesQuery(args: PdpTemplatesParams) {
  const query = useQuery({
    queryKey: [PdpQueryKey.PdpTemplate, args?.process],
    enabled: Boolean(args?.process),
    queryFn: async () => {
      const apiRetailer = userService.getAPIRetailer();
      const retailer = userService.getRetailer();

      const otherTargetVendor =
        apiRetailer === RetailerType.Target && retailer === RetailerType.Autozone
          ? { vendor: retailer?.toLowerCase() }
          : {};

      const { data } = await pdpApi.getPdpTemplates(apiRetailer?.toLowerCase(), { ...args, ...otherTargetVendor });
      return data;
    },
  });

  return query;
}
