import { Box, Tooltip } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { ReactElement } from 'react';
import toast from 'react-hot-toast';

interface StylesProps {
  fontFamily: 'monospace' | 'default';
  fontSize?: number;
  cursor?: string;
  isDemoMode: boolean;
  error: boolean;
}

const getStyles = ({ fontFamily, isDemoMode, error, fontSize, cursor }: StylesProps): Styles => ({
  hash: {
    backgroundColor: error ? theme.palette.error.light : theme.palette.grey['300'],
    borderRadius: '4px',
    padding: 0.5,
    textAlign: 'center',
    cursor: cursor ?? 'pointer',
    fontFamily: fontFamily === 'monospace' ? 'monospace' : undefined,
    filter: isDemoMode ? 'blur(3px)' : 0,
    fontSize,
  },
});

interface Props {
  value: string | number;
  copyMessage?: string;
  copyTooltip?: string;
  fontFamily?: 'monospace' | 'default';
  fontSize?: number;
  cursor?: string;
  disableCopy?: boolean;
  error?: boolean;
}

export function HashContainer({
  value,
  copyMessage,
  copyTooltip = 'Copy',
  fontFamily = 'monospace',
  disableCopy = false,
  error = false,
  fontSize,
  cursor,
}: Props): ReactElement {
  const styles = getStyles({ fontFamily, error, fontSize, cursor, isDemoMode: false });

  const handleCopy = () => {
    if (disableCopy) return;
    navigator.clipboard.writeText(String(value));
    toast.success(copyMessage);
  };

  return (
    <Tooltip title={copyTooltip} placement="top" disableHoverListener={disableCopy}>
      <Box sx={styles.hash} onClick={handleCopy}>
        {value}
      </Box>
    </Tooltip>
  );
}
