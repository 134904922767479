import { Box, Tab, Tabs } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { Modal } from 'common/ui/containers/modal';
import { userService } from 'common/services/user.service';
import { RetailerType } from 'common/constants/entities';
import { ExtendedContentEntry, PdpAttributeData } from 'common/types/common';
import { ProductAITabValue } from './constants';
import { PdpTemplateContent } from './pdp-template-content';
import { PdpHistoryContent } from './pdp-history-content';
import { PDPKeywords } from './pdp-keywords';

const styles: Styles = {
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tabs: { width: '100%', backgroundColor: '#FAFAFA' },
};

interface Props {
  pid: string;
  open: boolean;
  product: ExtendedContentEntry;
  onClose: () => void;
}

export function PdpAIModal({ pid, open, product, onClose }: Props): ReactElement {
  const pdpAttributes: PdpAttributeData | unknown =
    product?.ai_content?.optimizations?.history_v2?.pdp_attributes ?? null;

  const [selectedTab, setSelectedTab] = useState<ProductAITabValue>(ProductAITabValue.Template);

  const handleTabChange = (_: SyntheticEvent<Element, Event>, value: ProductAITabValue) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    if (open) return;
    setSelectedTab(ProductAITabValue.Template);
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} maxWidth="xl" title="PDP AI Template Settings">
      <Box sx={styles.container}>
        <Tabs onChange={handleTabChange} value={selectedTab} sx={styles.tabs} centered>
          <Tab value={ProductAITabValue.Template} tabIndex={ProductAITabValue.Template} label="Template" />

          <Tab value={ProductAITabValue.Prompt} tabIndex={ProductAITabValue.Prompt} label="Prompt" />

          {userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon]) && (
            <Tab value={ProductAITabValue.Keywords} tabIndex={ProductAITabValue.Keywords} label="Keywords" />
          )}

          {pdpAttributes && (
            <Tab value={ProductAITabValue.History} tabIndex={ProductAITabValue.History} label="History" />
          )}
        </Tabs>

        {[ProductAITabValue.Prompt, ProductAITabValue.Template].includes(selectedTab) && (
          <PdpTemplateContent pid={pid} selectedTab={selectedTab} />
        )}

        {selectedTab === ProductAITabValue.Keywords && <PDPKeywords pid={pid} />}

        {selectedTab === ProductAITabValue.History && <PdpHistoryContent pdpAttributes={pdpAttributes} />}
      </Box>
    </Modal>
  );
}
