import { Route, getProtectedRoutes, getUnProtectedRoutes } from 'common/constants/routes';
import { handleActivePath } from 'common/utils';
import { useLayoutEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

export const chechIsRouteUnprotected = (location: Location) => {
  return Boolean(getUnProtectedRoutes().find(r => handleActivePath(r.path, location)));
};

export const findCurrentRoute = (location: Location) => {
  return [...getUnProtectedRoutes(), ...getProtectedRoutes()].find(r => handleActivePath(r.path, location));
};

export function useCurrrentRoute() {
  const location = useLocation();

  const [isProtectedRoute, setIsProtectedRoute] = useState<boolean>(!chechIsRouteUnprotected(location));
  const [route, setRoute] = useState<Route>(findCurrentRoute(location));

  useLayoutEffect(() => {
    setIsProtectedRoute(!chechIsRouteUnprotected(location));
    setRoute(findCurrentRoute(location));
  }, [location]);

  return { isProtectedRoute, route };
}
