import { useMemo } from 'react';
import { ExtendedContentEntry } from 'common/types/common';

const transformAttributeName = (attribute: string) => {
  return attribute?.toLowerCase().replace(/_/g, '');
};

interface Props {
  attributeKeys: Array<string>;
  product: ExtendedContentEntry;
}

export function useProductIssues({ attributeKeys, product }: Props) {
  const issues = useMemo(() => {
    if (!product?.issues) return [];

    return product.issues.filter(i =>
      attributeKeys?.some(key => transformAttributeName(i.attribute) === transformAttributeName(key))
    );
  }, [attributeKeys, product?.issues]);

  return { issues };
}
