import { MENU_WIDTH_XL } from 'common/constants/sizes';
import { usePathsMatch } from './use-paths-match';

const routesWithoutMenu = [
  '/content/quick-review',
  '/ai-benchmark',
  '/image-scoring',
  '/compare-benchmark',
  '/background-creator',
];

export function useMenuWidth() {
  const hiddenMenu = usePathsMatch(routesWithoutMenu);

  return hiddenMenu ? 0 : MENU_WIDTH_XL;
}
