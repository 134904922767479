import { Styles } from 'common/types/styles';

export const getBTNsStyles = (): Styles => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5',
    justifyContent: 'flex-end',
    width: '100%',
  },
  toggle: { fontSize: 12 },
});
