import { useQuery } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { productsApi } from 'common/services/api/products/products-api.service';
import { TargetProductsFilters } from 'common/services/api/products/products-api.types';
import { userService } from 'common/services/user.service';

export enum ProductsQueryKey {
  GetProducts = 'GetProducts',
}

export function useProductsQuery(args?: TargetProductsFilters, enabled?: boolean) {
  const query = useQuery({
    queryKey: [ProductsQueryKey.GetProducts, args],
    staleTime: 1000 * 10,
    enabled,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await productsApi.getProducts(retailer, args);
      return data;
    },
  });
  return query;
}

export function useTargetProductById(id: string) {
  const query = useQuery({
    queryKey: [ProductsQueryKey.GetProducts, id],
    staleTime: 1000 * 10,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await productsApi.getProductById(retailer, id);
      return data;
    },
  });
  return query;
}
