import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { Typography } from '@mui/material';

export interface ActionsOption {
  name: string;
  value: string | number;
  icon?: React.ReactNode;
}

interface Props {
  open: boolean;
  options: ActionsOption[];
  anchor: HTMLElement | null;
  onClose: () => void;
  onClick: (value: string | number) => void;
}

const optionHeight = 50;
const iconSize = 10;
const textSize = 12;

const getStyles = (): Styles => ({
  container: { padding: theme.spacing(0, 2.5), display: 'flex', flexDirection: 'column' },
  option: {
    height: optionHeight,
    fontSize: textSize,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    width: iconSize,
    height: iconSize,
    marginRight: theme.spacing(1),
  },
  text: {
    marginLeft: 1,
    fontSize: 12,
  },
  divider: {
    borderColor: '#F4F4F5',
  },
});

export function ActionsPopover({ open, options, anchor, onClose, onClick }: Props) {
  const styles = getStyles();

  const handleClick = (value: string | number) => {
    onClick(value);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.container}>
        {options.map((option, index) => (
          <React.Fragment key={option.value}>
            <Box sx={styles.option} onClick={() => handleClick(option.value)}>
              {option.icon}
              <Typography sx={styles.text}>{option.name}</Typography>
            </Box>
            {index !== options.length - 1 && <Divider sx={styles.divider} />}
          </React.Fragment>
        ))}
      </Box>
    </Popover>
  );
}
