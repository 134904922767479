import { Box, CircularProgress } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement } from 'react';

const getWidth = (type: 'fullwidth' | 'fullscreen') => {
  switch (type) {
    case 'fullscreen':
      return '100vw';
    case 'fullwidth':
      return '100%';
    default:
      return 'unset';
  }
};

interface StylesProps {
  width: string;
}

const getStyles = ({ width }: StylesProps): Styles => ({
  container: { width, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
});

interface Props {
  type: 'fullwidth' | 'fullscreen';
}

export function LoadingContainer({ type }: Props): ReactElement {
  const styles = getStyles({ width: getWidth(type) });

  return (
    <Box sx={styles.container}>
      <CircularProgress color="primary" />
    </Box>
  );
}
