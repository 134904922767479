import { Box, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Styles } from 'common/types/styles';
import { ReactElement, useRef, useState } from 'react';
import { theme } from 'common/constants/theme';
import { SelectFieldOption } from '../select-field';
import { LightTooltip } from '../../containers/light-tooltip';

interface StylesProps {
  status: string;
  color: string;
  pointer: boolean;
  disabled?: boolean;
  hideValueText?: boolean;
  size: 'small' | 'large';
  fullWidth?: boolean;
}

const getStyles = ({ pointer, hideValueText, size, fullWidth }: StylesProps): Styles => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: pointer ? 'pointer' : 'default',
    width: fullWidth ? '100%' : 'unset',
  },
  status: {
    padding: theme.spacing(0.5, 1.5),
    minWidth: hideValueText ? 10 : 80,
    width: fullWidth ? '100%' : 'unset',
    fontWeight: 400,
    boxSizing: 'border-box',
    color: '#191919',
    backgroundColor: 'rgba(0, 63, 225, 0.15)',
    borderRadius: '110px',
    textTransform: 'capitalize',
    textAlign: 'center',
    fontSize: size === 'large' ? 13 : 12,
  },
  optionsContainer: { display: 'flex', flexDirection: 'column', width: 100 },
  option: {
    padding: 1,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 400,
    fontSize: 14,
    cursor: 'pointer',
  },
});

interface Props {
  value: string;
  color?: string;
  showExpand?: boolean;
  options?: Array<SelectFieldOption>;
  tooltip?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  disabled?: boolean;
  hideValueText?: boolean;
  size?: 'small' | 'large';
  fullWidth?: boolean;
}

export function StatusDropdown({
  value,
  options,
  color,
  onChange,
  showExpand = false,
  tooltip,
  disabled,
  hideValueText,
  size = 'large',
  fullWidth,
}: Props): ReactElement {
  const styles = getStyles({
    status: value,
    pointer: options?.length && !disabled,
    color,
    disabled,
    hideValueText,
    size,
    fullWidth,
  });

  const [statusAnchor, setStatusAcnhor] = useState<HTMLDivElement | null>(null);

  const statusRef = useRef<HTMLDivElement>();

  const handleStatusClick = () => {
    if (!options?.length || disabled) return;
    setStatusAcnhor(statusRef.current);
  };

  const handlePopoverClose = () => {
    setStatusAcnhor(null);
  };

  return (
    <>
      <Box sx={styles.container} onClick={handleStatusClick}>
        <LightTooltip title={tooltip ?? ''} placement="top" disableHoverListener={!tooltip}>
          <Box sx={styles.status} ref={statusRef}>
            {!hideValueText ? value : ''}
          </Box>
        </LightTooltip>
        {showExpand && <ExpandMoreIcon fontSize="small" />}
      </Box>
      {Boolean(options?.length) && (
        <Popover
          open={Boolean(statusAnchor)}
          anchorEl={statusAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
        >
          <Box sx={styles.optionsContainer}>
            {options
              .filter(o => o.value !== value)
              .map(o => (
                <Box
                  key={o.value}
                  sx={styles.option}
                  onClick={() => {
                    if (onChange) onChange(o.value);
                    handlePopoverClose();
                  }}
                >
                  {o.label}
                </Box>
              ))}
          </Box>
        </Popover>
      )}
    </>
  );
}
