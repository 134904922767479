import { ReactElement, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

const __lsKey = 'demo';

export const demoCtx = createContext({
  active: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActive: (active: boolean) => undefined,
});

interface Props {
  children: ReactNode;
}

export function DemoContext({ children }: Props): ReactElement {
  const [active, setActive] = useState<boolean>(localStorage.getItem(__lsKey) === '1');

  const value = useMemo(() => {
    return { active, setActive };
  }, [active]);

  useEffect(() => {
    localStorage.setItem(__lsKey, active ? '1' : '0');
  }, [active]);

  return <demoCtx.Provider value={value}>{children}</demoCtx.Provider>;
}

export function useDemoContext() {
  const ctx = useContext(demoCtx);

  return ctx;
}
