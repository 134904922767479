import { createTheme } from '@mui/material';
import { background, paper, primary, primaryDark, primaryLight } from './colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      dark: primaryDark,
      light: primaryLight,
    },
    background: {
      default: background,
      paper,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: { fontSize: 27, fontWeight: 500, lineHeight: '33px' },
  },
  components: {
    MuiTextField: { styleOverrides: {} },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          textTransform: 'capitalize',
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        },
      },
    },
    MuiDivider: { styleOverrides: { root: { borderColor: '#F4F4F5' } } },
    MuiTableCell: {
      styleOverrides: { root: { borderColor: '#fff', borderWidth: '2px', color: 'inherit', fontSize: 'inherit' } },
    },
    MuiTableRow: { styleOverrides: { root: { color: 'inherit', fontSize: 'inherit' } } },
    MuiTableHead: { styleOverrides: { root: { backgroundColor: '#fff', color: '#6C6D76', fontSize: 12 } } },
    MuiTableBody: { styleOverrides: { root: { backgroundColor: '#FAFAFA', fontSize: 14 } } },
    MuiTypography: { styleOverrides: { root: { letterSpacing: '1px' } } },
  },
});
