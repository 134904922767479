import { useQuery } from '@tanstack/react-query';
import {
  AllVendorsTitleMap,
  PocRetailers,
  RetailerType,
  SyndigoRetailers,
  WalmartRetailers,
} from 'common/constants/entities';
import { usersApi } from 'common/services/api/users/users-api.service';
import { userService } from 'common/services/user.service';
import { UserFilter } from 'common/types/common';

export enum UsersQueryKey {
  UsersList = 'UsersList',
  Me = 'Me',
}

export function useUsersQuery(args?: UserFilter) {
  const query = useQuery({
    queryKey: [UsersQueryKey.UsersList, args],
    staleTime: 1000 * 10,
    queryFn: async () => {
      const { data } = await usersApi.getUsers(args);
      return data;
    },
  });
  return query;
}

export function useMeQuery(enabled = true) {
  const query = useQuery({
    queryKey: [UsersQueryKey.Me],
    staleTime: 99999999,
    enabled,
    queryFn: async () => {
      const { data } = await usersApi.getMe();
      return data;
    },
    onSuccess: data => {
      const isAdmin = data.roles?.some(item => item?.name === 'ccs_admin');

      const allRetailers = [
        RetailerType.Target,
        RetailerType.Instacart,
        RetailerType.Autozone,
        ...WalmartRetailers,
        ...PocRetailers,
        ...SyndigoRetailers,
      ];

      const availableVendorsForUsers = data?.vendors?.length
        ? data?.vendors?.map(item => AllVendorsTitleMap[item?.name as RetailerType]?.value)
        : allRetailers;
      const avialableVendors = isAdmin ? allRetailers : availableVendorsForUsers;

      const defaultRetailer = localStorage.getItem('retailer');

      const retailer = avialableVendors?.includes(defaultRetailer) ? defaultRetailer : avialableVendors[0];

      userService.setUser(data.user);
      userService.setPermissions(data.permissions);
      userService.setRoles(data.roles.map(r => r.name));
      userService.setLevel(data.level);
      userService.setRetailer(retailer);
    },
    retry: false,
  });
  return query;
}
