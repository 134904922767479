import { RetailerType } from 'common/constants/entities';
import { useCategoryQuery } from 'common/hooks/api/queries/use-category-query';
import { useContentQuery } from 'common/hooks/api/queries/use-content-query';
import { userService } from 'common/services/user.service';
import { useSelectedContentContext } from 'common/ui/shared/selected-content-ctx';

export function useSelectedProductQuery() {
  const { selectedContent } = useSelectedContentContext();

  const query = useContentQuery({ pid: selectedContent });

  return query;
}

export function useSelectedCategoryQuery() {
  const { data: productData } = useSelectedProductQuery();

  const categoryQuery = useCategoryQuery({
    id:
      userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)
        ? productData?.category_id
        : productData?.item_type,
    enabled: true,
  });

  return categoryQuery;
}
