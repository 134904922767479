export const formatMoney = (value: number, options: Intl.NumberFormatOptions = {}): string => {
  if (!value) return '0';
  return new Intl.NumberFormat('en', { ...options, currency: options.currency || 'USD', style: 'currency' }).format(
    value
  );
};

export const formatNumber = (value: number): string => {
  if (!value) return '0';
  return new Intl.NumberFormat().format(value);
};

export function getRandomNumber(min: number, max: number): number {
  if (min > max) {
    throw new Error('Min should be less than or equal to max');
  }

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
